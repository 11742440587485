import { NgModule, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Component, Inject, Renderer2, ElementRef} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { DialogComponent } from 'src/app/back-office/components/dialog/dialog.component';
import config from 'config/default.json';
declare var $: any;

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class ClassifyModule { 
  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    private cdRef: ChangeDetectorRef,
    private helper: HelperModule,
    private apiService: ApiServiceModule,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    ) {}
    config = config;
    that: any;
    pdfContainer: any;

    init(that:any){
      this.that = that;
    }
    showClassified(){
      this.that.cleanedActive = false;
      this.that.editedActive = false;
      this.that.classifiedActive = true;
      this.loadClassifyData();
    }
    loadClassifyData(){
      this.that.inProgress = true;
      let project = this.that.projectInfos;
      let data = {
        platform_infos: this.that.platformInfos,
        project: project,
        vcl_params: this.that.platformInfos['origin'] == 'virtualclass'? [{key: 'con.id_country', value: project.id_country}, {key: 'con.id_cycle', value: project.id_cycle}, {key: 'con.id_region', value: project.id_region}, {key: 'con.id_institution', value: project.id_institution}]: null,
      };
      this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/project/load_classify_data', data).subscribe((response:any)=>{
        this.that.inProgress = false;
        this.that.projectInfos['last_task'] = 'task-classified'
        this.that.classifyData = response;
        this.that.activatePage('task-classified');
        this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/project/save_current_task', {id_project: this.that.projectInfos['id'], cuurent_task: 'task-classified', platform_infos: this.that.platformInfos}).subscribe();
      })
    }

    computeClassification(contentSource: string){
      if(this.that.contentData.length){
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '50%',
          position: {top: '30px' },
          data: {page: 'confirmation', text: 'This action will erase your previous Classification... Continue ?'}
        });
        dialogRef.afterClosed().subscribe((toContinue:boolean) => {
          if(toContinue){
            this.that.inProgress = true;
            let contentsInfos = {
              content_source: contentSource,
              platform_infos: this.that.platformInfos,
              user_infos: this.that.userInfos,
              project_infos: this.that.projectInfos
            };
            const formData = new FormData();
            formData.append('data', JSON.stringify(contentsInfos));
            
            this.apiService.queryData(config.master.flapi.url + '/enovsky/classify_content', formData).subscribe((response:any)=>{
              this.that.inProgress = false;
              this.that.projectInfos['last_task'] = 'task-classified';
              this.that.classifyData = response;
              this.showClassified();
            })
          }
        })
        
      }else this.helper.showToast('Edited content not exist...');
    }
    
    
}
