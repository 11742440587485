
<div style="background-color: white; padding: 20px 20px 0px 20px; ">
    <div class="class-erea" fxLayout="row" fxLayout.xs="column">
        <div class="class-mat-field" fxFlex="33" style="height: 100px;">
            <h3 class="stat-title">Registered students</h3>
            <div class="stat-value">{{this.data['registration']?.nb_student ?? 0}}</div>
            <div class="stat-trend">Total: {{this.data['registration']?.total_student ?? 0}}</div>
        </div>
        <div class="separator"></div>
        <div class="class-mat-field" fxFlex="33" style="height: 100px;">
            <div style="display: flex; justify-content: center;;">
                <div>
                    <h3 class="stat-title">Android</h3>
                    <div class="stat-value">{{this.data['registration']?.nb_platform_android ?? 0}}</div>
                    <div class="stat-trend">Total: {{this.data['registration']?.total_platform_android ?? 0}}</div>
                </div>
                <div style="width: 3px; background-color: whitesmoke; margin: 0 10px 0 10px;"></div>
                <div>
                    <h3 class="stat-title">IOS</h3>
                    <div class="stat-value">{{this.data['registration']?.nb_platform_ios ?? 0}}</div>
                    <div class="stat-trend">Total: {{this.data['registration']?.total_platform_ios ?? 0}}</div>
                </div>
            </div>
        </div>
        <div class="separator"></div>
        <div class="class-mat-field" fxFlex="33" style="height: 100px;">
            <h3 class="stat-title">Subscriptions</h3>
            <div class="stat-value">{{this.data['registration']?.nb_subscription ?? 0}}</div>
            <div class="stat-trend">Total: {{this.data['registration']?.total_subscription ?? 0}}</div>
        </div>
        <div class="separator"></div>
        <div class="class-mat-field" fxFlex="33">
           <h2>ABonnement</h2>
        </div>
    </div>
</div>

<div style="background-color: white; padding: 20px 20px 0px 20px;">
    <div class="class-erea" fxLayout="row" fxLayout.xs="column" style="height: 400px;">
        <div class="class-mat-field" fxFlex="80">
            <canvas class="student-evolution"></canvas>
        </div>
        <div class="separator"></div>
        <div class="class-mat-field" fxFlex="20">
            <h2>Plateforme</h2>
        </div>
    </div>
</div>
<div style="background-color: white; padding: 20px 20px 0px 20px;">
    <div class="class-erea" fxLayout="row" fxLayout.xs="column" style="height: 400px;">
        <div class="class-mat-field" fxFlex="80" style="padding-top: 5px;">
            <div fxLayout="row" fxLayout.xs="column">
                <div class="class-mat-field" fxFlex="40">
                    <mat-select placeholder="User" [formControl]="userControl">
                        <input class="col-input-filter" [formControl]="userInputControl">
                        <mat-option [value]="0">Choose</mat-option>
                        <mat-option [value]="result['id_user']" *ngFor="let result of allUsers; let i = index">{{result['first_name']}} {{result['last_name']}} ({{result['email']}})</mat-option>
                    </mat-select>
                </div>
                <div class="separator"></div>
                <div class="class-mat-field" fxFlex="20"  fxLayoutAlign="center center">
                    <button class="button-validate-small" (click) = "loadDailyProcess()">Refresh</button>
                </div>
            </div>
            <canvas class="user-daily-process"></canvas>
        </div>
        <div class="separator"></div>
        <div class="class-mat-field" fxFlex="20">
            <h2>Plateforme</h2>
        </div>
    </div>
</div>
<div style="background-color: white; padding: 20px 20px 0px 20px;">
    <div class="class-erea" fxLayout="row" fxLayout.xs="column" style="height: 400px;">
        <div class="class-mat-field" fxFlex="30">
            <canvas class="global-project-content"></canvas>
        </div>
        <div class="separator"></div>
        <div class="class-mat-field" fxFlex="70">
            <canvas class="project-content"></canvas>
        </div>
    </div>
</div>
<div style="background-color: white; padding: 20px 20px 0px 20px;">
    <div class="class-erea" fxLayout="row" fxLayout.xs="column" style="height: 400px;">
        <div class="class-mat-field" fxFlex="70">
            <canvas class="raw-content"></canvas>
        </div>
        <div class="separator"></div>
        <div class="class-mat-field" fxFlex="30">
            <canvas class="global-raw-content"></canvas>
        </div>
    </div>
</div>
<div style="background-color: white; padding: 20px 20px 0px 20px;">
    <div class="class-erea" fxLayout="row" fxLayout.xs="column" style="height: 400px;">
        <div class="class-mat-field" fxFlex="20">
            <h2>Inscriptions</h2>
        </div>
        <div class="separator"></div>
        <div class="class-mat-field" fxFlex="80">
            <canvas class="available-content"></canvas>
        </div>
    </div>
</div>
<div style="padding-top: 20px; background-color: white;"></div>