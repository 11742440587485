import { ChangeDetectorRef, Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
import config from 'config/default.json';
import { MainComponent } from 'src/app/back-office/main/main.component';
@Component({
  selector: 'app-main-enovsky',
  templateUrl: './main-enovsky.component.html',
  styleUrls: ['./main-enovsky.component.scss']
})
export class MainEnovskyComponent {
  constructor(
    private mainComponent: MainComponent,
    private elRef:ElementRef,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private apiService: ApiServiceModule,
    ) {}
  pageId = 'documentation';
  userInfos: { [x:string]: any } = new Map();
  
  ngOnInit(){
    this.sharedService.data.subscribe(data => {
      if(data['side_enovsky_page_id']){
        this.pageId = data['side_enovsky_page_id'];
        let param = {key: 'env_last_page', value: this.pageId, id_user: this.userInfos['id_user']};
        this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/administration/save_settings', param).subscribe((response)=>{});;
      }
    });
    this.mainComponent.eventShare.subscribe((userInfos) => {
      this.userInfos = userInfos;
      this.pageId = this.userInfos?.['navigation_infos']?.['env_last_page'] || this.pageId;
    });
  }
}
