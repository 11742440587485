import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import config from 'config/default.json';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-detail-advertisment',
  templateUrl: './detail-advertisment.component.html',
  styleUrls: ['./detail-advertisment.component.scss']
})
export class DetailAdvertismentComponent {
  constructor(
    private elRef:ElementRef,
    private cdRef: ChangeDetectorRef,
    public helper: HelperModule,
    public dialog: MatDialog,
    private apiService: ApiServiceModule,
    private sharedService: SharedService,
    public sanitizer: DomSanitizer) {

  }
  @Input() rowInfos: { [x:string]: any } = new Map();
  @Input() userInfos: { [x:string]: any } = new Map();
  @Input() productData: any[] = [];;
  @Input() promoTypes: any[] = [];
  @Input() platformInfos: { [x:string]: any } = new Map();
  @Output() updateAdvertisment = new EventEmitter<any>();
  @Output() inProgress = new EventEmitter<any>();

  nameControl = new FormControl();
  fullNameControl = new FormControl();
  typeControl = new FormControl();
  descriptionControl = new FormControl();
  formDataAndFiles:any  = new FormData();
  activeControl = new FormControl();

  ndApiUrl = config.master.ndapi.url;

  ngOnInit() {
    this.nameControl.setValue(this.rowInfos['name']);
    this.fullNameControl.setValue(this.rowInfos['full_name']);
    this.typeControl.setValue(this.rowInfos['type']);
    this.descriptionControl.setValue(this.rowInfos['description']);
    this.activeControl.setValue(this.rowInfos['active']);
  }

  applyUpdate(){
    let name = this.nameControl.value;
    let fullName = this.fullNameControl.value;
    let type = this.typeControl.value;
    let description     = this.descriptionControl.value;
    let active = this.activeControl.value;

    if(name){
      let data = {
        name: name,
        full_name: fullName,
        type: type,
        active: active,
        description: description,
        id_advertisment: this.rowInfos['id'],
        user_infos: this.userInfos,
        platform_infos: this.platformInfos,
      }
      this.sharedService.sendData({in_progress: true});
      this.formDataAndFiles.delete('data');
      this.formDataAndFiles.append('data', JSON.stringify(data));
      this.apiService.uploadDataAndFile(config.master.ndapi.url +'/enovsky/web/advertisment/update', this.formDataAndFiles).subscribe((response:any)=>{
        this.helper.showToast(response.message);
        this.sharedService.sendData({in_progress: false});
      })
    }else this.helper.showToast('Not all fields are filled...')
    
  }
  onFileSelected(event:any){
    let files = event.target.files;
    for(var i = 0; i < files.length; i++) this.formDataAndFiles.append("file", files[i]);
  }
}
