import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
import config from 'config/default.json';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-content',
  templateUrl: './upload-content.component.html',
  styleUrls: ['./upload-content.component.scss']
})
export class UploadContentComponent {
  constructor(
    private elRef:ElementRef,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private apiService: ApiServiceModule,
    private helper: HelperModule,
    public dialog: MatDialog,
    private sharedService: SharedService
    ) {}
  @Input() platformInfos: { [x:string]: any } = new Map();
  formDataAndFiles:any  = new FormData();
  successMessage: string = '';
  warningMessage: string = '';
  errorMessage: string = '';
  nbFiles:number = 0;

  ngOnInit(){
    this.manageSelectedFiles();
  }
  uploadContent(data: any){
    if(this.formDataAndFiles.getAll('file').length){
      this.formDataAndFiles.append('data', JSON.stringify(data));
      this.successMessage = '';
      this.sharedService.sendData({in_progress: true});
      this.apiService.uploadDataAndFile(config.master.flapi.url + '/enovsky/upload_content', this.formDataAndFiles).subscribe((response:any)=>{
        if(response.status){
          this.errorMessage = '';
          this.successMessage = response.success;
          this.warningMessage = response.warning;
        }else{
          this.successMessage = '';
          this.warningMessage = '';
          this.errorMessage = response.error;
        }
        this.sharedService.sendData({in_progress: false});
      });
    }else this.helper.showToast("No file selected..."); 
  }
  onFileDroped(event:any){
    event.preventDefault();
    let files = event.dataTransfer.files;
    for(var i = 0; i < files.length; i++) this.formDataAndFiles.append("file", files[i]);
    this.successMessage = this.formDataAndFiles.getAll('file')!.length+' nselected file(s)';
    
  }
  onFileSelected(event:any){
    let files = event.target.files;
    for(var i = 0; i < files.length; i++) this.formDataAndFiles.append("file", files[i]);
    this.successMessage = this.formDataAndFiles.getAll('file').length+' selected file(s)';
  }
  manageSelectedFiles(){
    let that = this;
    let dragAndDropRef = this.elRef.nativeElement.querySelector('.drag_drop');
    this.elRef.nativeElement.querySelector('#select_files').addEventListener('change', (event:any)=>{
      that.nbFiles = event.target.files.length + that.nbFiles;
      dragAndDropRef.style.borderColor = '#333';
      that.successMessage = this.formDataAndFiles.getAll('file').length+' selected file(s)';
    }, false);
    
    dragAndDropRef.ondragover = function(event:any){
      this.style.borderColor = '#333';
      return false;
    }
    
    dragAndDropRef.ondragleave = function(event:any){
      this.style.borderColor = '#ccc';
      return false;
    }
  
 }
  resetUpload(){
    this.formDataAndFiles.delete('file');
    this.formDataAndFiles.delete('data');
    this.elRef.nativeElement.querySelector('#select_files').value = '';
    this.elRef.nativeElement.querySelector('.drag_drop').style.borderColor = '#ccc';
    this.successMessage = '';
    this.warningMessage = '';
    this.errorMessage = '';
  }
}
