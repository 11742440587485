import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
@Component({
  selector: 'app-side-enovsky',
  templateUrl: './side-enovsky.component.html',
  styleUrls: ['./side-enovsky.component.scss']
})
export class SideEnovskyComponent {
  constructor(
    private elRef:ElementRef,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private sharedService: SharedService
    ) {}
  @Input() userInfos:  { [x:string]: any } = new Map();
  enovskyPages = [{id_page: 'documentation', name: 'Documentation', icon: 'mark_unread_chat_alt'}, {id_page: 'vca-student-dashboard', name: 'VCA Dashboard', icon: 'dashboard'}, {id_page: 'vcl-student-dashboard', name: 'VCL Dashboard', icon: 'groups'}, {id_page: 'vca-student-manager', name: 'VCA Manager', icon: 'manage_accounts'}, 
            {id_page: 'vcl-student-manager', name: 'VCL Manager', icon: 'manage_accounts'}, {id_page: 'user-admin', name: 'User Admin', icon: 'groups'}, {id_page: 'server-admin', name: 'Server Admin', icon: 'engineering'}];
  activePage = 'dashboard-student';
  ngAfterViewInit(){
    let authorizations = this.userInfos?.['authorization']?.enovsky || [this.enovskyPages[0]];
    this.enovskyPages = authorizations.length? this.enovskyPages.filter((item:any)=> authorizations.includes(item.id_page)): [];
    
    this.activePage = this.userInfos?.['navigation_infos']?.env_last_page || this.activePage;
  }
  showHidePage(pageId:string){
    this.activePage = pageId;
    this.sharedService.sendData({side_enovsky_page_id: pageId, close_side_nav: true});
  }
}
