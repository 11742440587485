import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, Output, SimpleChanges } from '@angular/core';
import {FormControl, FormGroup, FormBuilder} from '@angular/forms';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import config from 'config/default.json';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';

@Component({
  selector: 'app-vcl-filter',
  templateUrl: './vcl-filter.component.html',
  styleUrls: ['./vcl-filter.component.scss']
})
export class VcFilterComponent {
  constructor(
    private elRef:ElementRef,
    private cdRef: ChangeDetectorRef,
    private apiService: ApiServiceModule,
    private helper: HelperModule,
    private sharedService: SharedService,
    private zone: NgZone
    ) {}
    countryControl = new FormControl();
    cycleControl = new FormControl();
    regionControl = new FormControl();
    institutionControl = new FormControl();
    modeControl = new FormControl();
  
    filteredInstitutions: { [x:string]: string|number|boolean }[] = [];
    searchInstitutionControl = new FormControl();

    countries: { [x:string]: string|number|boolean }[] = [];
    cycles: { [x:string]: string|number|boolean }[] = [];
    regions: { [x:string]: string|number|boolean }[] = [];
    institutions: { [x:string]: string|number|boolean }[] = [];
  
    selectedCountry: any = null;
    selectedCycle: any = null;
    selectedRegion: any = null;
    selectedInstitution: any = null;
  
    userFilter: any = new Map();
    
    @Input() userInfos: { [x:string]: any } = new Map();
    @Input() modes:any[] = [];
  
    @Input() pageId!: string;
    @Input() pageName?: string | undefined;
    @Output() filterDataOutputEvent = new EventEmitter<any>();

    dataUpdateFilter: any = {}
    showDepartment!: boolean;
  
    ngOnInit(){
      this.dataUpdateFilter = {
        parent_ids: null, 
        parent_id: null,
        user_filter: null, 
        key_update: "vcl_filter_"+this.pageId, 
        id_user: this.userInfos['id_user']
      }
      this.fillFilter();
      this.applySearch();
    }
    ngOnChanges(changes: SimpleChanges): void {
      if (changes['userInfos'] && !changes['userInfos'].firstChange) {
        this.fillFilter();
        this.applySearch();
        this.cdRef.detectChanges();
      }
    }
    fillFilter(){
      console.log(this.userInfos)
      let userNavigationInfos = this.userInfos['navigation_infos'];
      this.userFilter = userNavigationInfos && userNavigationInfos["vcl_filter_"+this.pageId]? userNavigationInfos["vcl_filter_"+this.pageId]: [];

      this.selectedCountry = this.userFilter.country || null;
      this.selectedCycle = this.userFilter.cycle || null;
      this.selectedRegion = this.userFilter.region || null;
      this.selectedInstitution = this.userFilter.institution || null;
      this.loadPreviousFilter();
      
    }
    loadPreviousFilter(){
      this.apiService.queryData(config.master.ndapi.url + '/virtualclass/infos_child/load_previous_filter', {user_filter: this.userFilter}).subscribe((response:any)=>{
        this.countries = response.country;
        this.cycles = response.cycle
        this.regions = response.region;
        this.institutions = response.institution;

        this.filteredInstitutions = this.institutions;

        this.countryControl.setValue(this.selectedCountry);
        this.cycleControl.setValue(this.selectedCycle);
        this.regionControl.setValue(this.selectedRegion);
        this.institutionControl.setValue(this.selectedInstitution);
        this.chargeData('auto');
      });
    }
    applySearch(){
      this.searchInstitutionControl.valueChanges.subscribe((text) => {
        return this.filteredInstitutions = this.institutions.filter(element => this.normalizeString(element['identifier']).includes(this.normalizeString(text)));
      });
    }
    normalizeString(str:any){
      return str.toString().toLowerCase().normalize("NFD").replace(/[̀-ͯ]/g, "")
    }
    
    updateRegion(){
      this.userFilter.country = this.countryControl.value;
      this.dataUpdateFilter.parent_id = this.countryControl.value;
      this.cycleControl.setValue(null);
      this.regionControl.setValue(null);
      this.institutionControl.setValue(null);

      this.userFilter.cycle = null;
      this.userFilter.region = null;
      this.userFilter.institution = null;
      this.dataUpdateFilter.user_filter = this.userFilter;
      this.apiService.queryData(config.master.ndapi.url + '/virtualclass/infos_child/region', this.dataUpdateFilter).subscribe((data:any)=>{
        this.regions = data;
      });
    }
    updateInstitution(){
      let penrentIds = [this.cycleControl.value, this.regionControl.value || null];
      this.userFilter.cycle = this.cycleControl.value;
      this.userFilter.region = this.regionControl.value;
      this.institutionControl.setValue(null);

      this.userFilter.institution = null;
      this.dataUpdateFilter.parent_ids = penrentIds;
      this.dataUpdateFilter.user_filter = this.userFilter;
      this.apiService.queryData(config.master.ndapi.url + '/virtualclass/infos_child/institution', this.dataUpdateFilter).subscribe((data:any)=>{
        this.institutions = data;
        this.filteredInstitutions = this.institutions;
      });
    }
    setInstitution(){
      this.userFilter.institution = this.institutionControl.value;
      this.dataUpdateFilter.user_filter = this.userFilter;
      this.apiService.queryData(config.master.ndapi.url + '/virtualclass/infos_child/save_user_navigation', this.dataUpdateFilter).subscribe((response:any)=>{
      });
    }
    loadPreviousFilters(){
      console.log(this.userFilter)
    }
  
    selectedElement(selectedOptions: FormControl, element: { [x:string]: string|number|boolean }): boolean {
      return Array.isArray(selectedOptions.value) && selectedOptions.value.includes(element['id']);
    }
  
    chargeData(action:string) {
      let selectedData = {
        country: this.countries.find((el:any)=> this.cycleControl.value == el['id']),
        cycle: this.cycles.find((el:any)=> this.cycleControl.value == el['id']),
        region: this.regions.find((el:any)=> this.regionControl.value == el['id']),
        institution: this.institutions.find((el:any)=> this.institutionControl.value == el['id'])
      };
      let data = {
        action: action,
        filter_data: selectedData,
        mode: this.modeControl.value? this.modeControl.value: []
      }
      this.filterDataOutputEvent.emit(data);
      
    }
}
