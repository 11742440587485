import { ChangeDetectorRef, Component , ElementRef, Inject, Renderer2, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  constructor(
    private apiService: ApiServiceModule,
    private helper: HelperModule,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { [x:string]: any }
    ) {}
  page:string = this.data['page'];
  
  //Connection As
  ngOnInit(){
    this.cdRef.detectChanges();
  }
  
  

  //Project Name
  validateAction(){
    this.sendBack(true);
  }
  sendProjectName(projectName:string){
    this.sendBack({confirm: true, project_name: projectName});
  }
  closeProjectCreation(){
    this.sendBack({confirm: false});
  }


  onClose(): void {
    this.sendBack(false);
  }
  sendBack(data:any){
    this.dialogRef.close(data);
  }

}
