<div class="login-container">
    <div [formGroup]="formGroup" class="login-content" style="position: relative;">
        <div class="page-inscription-header">
            <h1 style="margin-block-end: 0px; text-align: center;">Connexion</h1> 
        </div>
        <div class="separator"></div>
        <div fxLayout="row">
            <input fxFlex="100%" type="text" class="col-input" formControlName="mail" placeholder="Mail *" matInput />
        </div>
        <div class="separator"></div>
        <div fxLayout="row">
            <input fxFlex="100%" type="password" class="col-input" formControlName="password" placeholder="Password *" matInput />
        </div>
        <div class="separator"></div>
        <div class="separator"></div>
        <div style="height: 50px;" fxLayoutAlign="center center">
            <button class="button-validate" (click) = "authenticate()">Connect</button>
        </div>
        <div class="separator"></div>
        <p>Need a new account ? <a class="request_account" (click) = "registration()">Request new account</a></p>
        
    </div>
</div>