import { Component, Input } from '@angular/core';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import config from 'config/default.json';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-server-admin',
  templateUrl: './server-admin.component.html',
  styleUrls: ['./server-admin.component.scss']
})
export class ServerAdminComponent {
  constructor(
    public helper: HelperModule,
    private apiService: ApiServiceModule,
    private sharedService: SharedService,) {}
  @Input() userInfos: { [x:string]: any } = new Map();
  flapiActive:boolean = false;
  jobNameControl = new FormControl();
  jobTypeControl = new FormControl();
  serverControl = new FormControl();
  hostControl = new FormControl();
  userControl = new FormControl();
  portControl = new FormControl();
  passwordControl = new FormControl();
  rootControl = new FormControl();
  jobStatus = '';
  jobDuration = '';
  jobStartDateServer = '';
  jobNameServer = '';
  jobDetails = '';
  jobMessage = '';

  ngOnInit(): void {
    this.jobNameControl.setValue('database');
    this.jobTypeControl.setValue('download');
    const formData = new FormData();
    this.apiService.queryData(config.master.flapi.url + '/manager/check_flapi', formData).subscribe((response: any) => {
      if(response.status) this.flapiActive = true;
      let job = response.job;
      this.jobStatus = job.status;
      this.jobDuration = job.duration;
      this.jobStartDateServer = job.start_date;
      this.jobNameServer = job.name_server;
      this.jobDetails = job.details;
      this.jobMessage = job.message;
    })
  }
  backUpServerData(){
    let data = {
      type: this.jobTypeControl.value, //backup, download
      name: this.jobNameControl.value,//job name, database, project, content, database_content_project_service
      server: this.serverControl.value? this.serverControl.value: 'backup', //'dedicated',
      host: this.hostControl.value,
      user: this.userControl.value,
      port: this.portControl.value,
      password: this.passwordControl.value,
      root: this.rootControl.value
    }
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    if (data.type == 'download')
      this.apiService.queryFileAndData(config.master.flapi.url + '/manager/backup', formData).subscribe((response: any) => {
        let fileName = response.headers.get('Content-Type');
        const file = new Blob([response.body]);
        var url = window.URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
        this.sharedService.sendData({in_progress: false});
      })
    else
    this.apiService.queryData(config.master.flapi.url + '/manager/backup', formData).subscribe((response: any) => {
      this.jobStatus = response.status;
      this.jobDuration = response.duration;
      this.jobStartDateServer = response.start_date;
      this.jobNameServer = response.name_server;
      this.jobDetails = response.details;
      this.jobMessage = response.message;
      this.sharedService.sendData({in_progress: false});
    })
    
    /* this.apiService.queryFile(config.master.flapi.url + '/manager/backup', formData).subscribe((arrayBuffer:any)=>{
      //console.log(response)
      let mimeType = type == 'database'? 'sql': 'zip';
        var file = new Blob([arrayBuffer], {type: 'application/'+mimeType});
        var url = window.URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.download = 'test_image.jpeg';
        link.click();
        URL.revokeObjectURL(url);

      this.sharedService.sendData({in_progress: false});
    }); */
  }
}
