<div fxLayout="row" fxLayout.xs="column">
    <div fxFlex="33.33%" style="padding: 0 10px 0 10px;">
        <div fxLayout="row" style="padding-top: 15px;" class="element-style">
            <div class="label-style" fxFlex="30%">Name</div>
            <div fxFlex="70%">
                <input class="col-input" [formControl]="nameControl" placeholder="Name" style="margin: 0;">
            </div>
        </div>
        <div *ngIf="rowInfos['promo_type'] != 'classic'" fxLayout="row" class="element-style">
            <div class="label-style" fxFlex="30%">Promo Code</div>
            <div fxFlex="70%">
                <input class="col-input" [formControl]="promoCodeControl" placeholder="Promo Code" style="margin: 0;">
            </div>
        </div>
        <div fxLayout="row" style="padding-top: 15px;" class="element-style">
            <div class="label-style" fxFlex="30%">Product</div>
            <div fxFlex="70%">
                <mat-select placeholder="Product" [formControl]="productControl">
                    <mat-option [value]="element['id']" *ngFor="let element of productData; let i = index">{{element['name']}}</mat-option>
                </mat-select>
            </div>
        </div>
    </div>
    <div fxFlex="33.33%" style="padding: 0 10px 0 10px;">
        <div *ngIf="rowInfos['promo_type'] == 'classic'" fxLayout="row" class="element-style">
            <div class="label-style" fxFlex="30%">Price</div>
            <div fxFlex="70%">
                <input class="col-input" [formControl]="priceControl" placeholder="Price" style="margin: 0;">
            </div>
        </div>
        <div *ngIf="rowInfos['promo_type'] != 'classic'" fxLayout="row" class="element-style">
            <div class="label-style" fxFlex="30%">Price Rate</div>
            <div fxFlex="70%">
                <input class="col-input" [formControl]="priceRateControl" placeholder="%" style="margin: 0;">
            </div>
        </div>
        <div *ngIf="rowInfos['promo_type'] == 'classic'" fxLayout="row" class="element-style">
            <div class="label-style" fxFlex="30%">Credit</div>
            <div fxFlex="70%">
                <input class="col-input" [formControl]="creditControl" placeholder="Credit" style="margin: 0;">
            </div>
        </div>
        <div *ngIf="rowInfos['promo_type'] != 'classic'" fxLayout="row" class="element-style">
            <div class="label-style" fxFlex="30%">Credit Rate</div>
            <div fxFlex="70%">
                <input class="col-input" [formControl]="creditRateControl" placeholder="%" style="margin: 0;">
            </div>
        </div>
    </div>
    <div fxFlex="33.33%" style="padding: 0 10px 0 10px;">
        <div fxLayout="row" style="padding-top: 15px;" class="element-style">
            <div class="label-style" fxFlex="30%">Type</div>
            <div fxFlex="70%">
                <mat-select placeholder="Type" [formControl]="typeControl">
                    <mat-option [value]="'prime_sponsor'">Prime Sponsor</mat-option>
                    <mat-option [value]="'promotion'">Promotion</mat-option>
                </mat-select>
            </div>
        </div>
        <div *ngIf="rowInfos['promo_type'] == 'classic'" fxLayout="row" class="element-style">
            <div class="label-style" fxFlex="30%">Nb Months</div>
            <div fxFlex="70%">
                <input class="col-input" [formControl]="durationControl" placeholder="Duration" style="margin: 0;">
            </div>
        </div>
        <div *ngIf="rowInfos['promo_type'] != 'classic'" fxLayout="row" class="element-style">
            <div class="label-style" fxFlex="30%">Active Period</div>
            <div fxFlex="70%">
                <div class="date-input">
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate [formControl]="beginDateControl" placeholder="Start">
                        <input matEndDate [formControl]="expirationDateControl" placeholder="End">
                    </mat-date-range-input>
                    <mat-icon matDatepickerToggleIcon fontIcon="close_small" style="font-size: 18px; display: flex; align-items: center;" (click)="resetDate()"></mat-icon>
                    <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </div>
            </div>
        </div>
        <div *ngIf="rowInfos['promo_type'] != 'classic'" fxLayout="row" class="element-style">
            <div class="label-style" fxFlex="30%">Active</div>
            <div fxFlex="70%">
                <mat-select placeholder="Active" [formControl]="activeControl">
                    <mat-option [value]="false">False</mat-option>
                    <mat-option [value]="true">True</mat-option>
                </mat-select>
            </div>
        </div>
    </div>
</div>
<div style="padding-top: 15px;"></div>
<div fxLayoutAlign="center center">
    <button class="button-validate-small" (click) = "applyUpdate()">Validate</button>
</div>