<h2>Server Administration</h2>
<div class="container">
    <div class="card" style="position: relative; width: 70%;" >
        <div>
            <div>Flapi status</div>
            <div style="position: relative; display: flex; align-items: center; margin-left: 15px;">
                <label>OFF</label>
                <span class="switch-toggle" [ngClass]="{'switch-checked': flapiActive}">
                  <small></small>
                </span>
                <label>ON</label>
            </div>
        </div>
        <div class="separator"></div>
        <div class="separator"></div>
        <div class="modal-dialog modal-xl modal-content">
            <div class="modal-header">
                <div><h3>Raw Contents Setting </h3></div>
            </div>
            <div class="modal-body">
                <div fxLayout="row" fxLayout.xs="column">
                    <div class="class-mat-field" fxFlex="50">
                       <!--  <div fxLayout="row" fxLayout.xs="column">
                            <div class="label-style" fxFlex="50">Job Type</div>
                            <div class="class-mat-field" fxFlex="50">
                                <mat-select placeholder="Type" [formControl]="jobTypeControl">
                                    <mat-option [value]="'backup'" >Backup</mat-option>
                                    <mat-option [value]="'download'" >Download</mat-option>
                                </mat-select>
                            </div>
                        </div> -->
                        <div class="separator"></div>
                        <div fxLayout="row" fxLayout.xs="column">
                            <div class="label-style" fxFlex="50">Job Name</div>
                            <div class="class-mat-field" fxFlex="50">
                                <mat-select placeholder="Name" [formControl]="jobNameControl">
                                    <mat-option [value]="'database_master_service'" >All</mat-option>
                                    <mat-option [value]="'database'" >Database</mat-option>
                                    <mat-option [value]="'master'" >Master</mat-option>
                                    <mat-option [value]="'service'" >Service</mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="separator"></div>
                        <div fxLayout="row" fxLayout.xs="column">
                            <div class="label-style" fxFlex="50">Server</div>
                            <div class="class-mat-field" fxFlex="50">
                                <mat-select placeholder="Server" [formControl]="serverControl">
                                    <mat-option [value]="'backup'" >Backup Server</mat-option>
                                    <mat-option [value]="'dedicated'" >Dedicated Server</mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="separator"></div>
                        <div *ngIf="serverControl.value == 'dedicated'">
                            <div fxLayout="row" fxLayout.xs="column">
                                <div class="label-style" fxFlex="50">Host Name</div>
                                <div class="separator"></div>
                                <div class="class-mat-field" fxFlex="50">
                                    <input type="text" class="col-input" style="margin: 1px;" placeholder="192.168.10.10" [formControl]="hostControl">
                                </div>
                            </div>
                            <div class="separator"></div>
                            <div fxLayout="row" fxLayout.xs="column">
                                <div class="label-style" fxFlex="50">User Name</div>
                                <div class="separator"></div>
                                <div class="class-mat-field" fxFlex="50">
                                    <input type="text" class="col-input" style="margin: 1px;" placeholder="User" [formControl]="userControl">
                                </div>
                            </div>
                            <div class="separator"></div>
                            <div fxLayout="row" fxLayout.xs="column">
                                <div class="label-style" fxFlex="50">Port</div>
                                <div class="separator"></div>
                                <div class="class-mat-field" fxFlex="50">
                                    <input type="text" class="col-input" style="margin: 1px;" placeholder="22" [formControl]="portControl">
                                </div>
                            </div>
                            <div class="separator"></div>
                            <div fxLayout="row" fxLayout.xs="column">
                                <div class="label-style" fxFlex="50">Password</div>
                                <div class="separator"></div>
                                <div class="class-mat-field" fxFlex="50">
                                    <input type="text" class="col-input" style="margin: 1px;" placeholder="******" [formControl]="passwordControl">
                                </div>
                            </div>
                            <div class="separator"></div>
                            <div fxLayout="row" fxLayout.xs="column">
                                <div class="label-style" fxFlex="50">Root Path</div>
                                <div class="separator"></div>
                                <div class="class-mat-field" fxFlex="50">
                                    <input type="text" class="col-input" style="margin: 1px;" placeholder="/root" [formControl]="rootControl">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="padding-right: 20px;"></div>
                    <div class="class-mat-field" fxFlex="50">
                        <div>{{jobStatus}}: Duration: {{jobDuration}}</div>
                        <div>{{jobStartDateServer}}</div>
                        <div>{{jobNameServer}}</div>
                        <div>{{jobDetails | json}}</div>
                        <div>{{jobMessage  | json}}</div>
                    </div>
                </div>
                
            </div>
            <hr>
            <div class="modal-footer" style="margin-top: 5px;">
                <button type="button" class="button-validate-small" (click)="backUpServerData()">Validate</button>
            </div>
        </div>
       <!--  <div style="padding-top: 0px;">
            <div style="justify-content: center;">
                <button type="button" class="button-secondary-small" (click)="backUpServerData('project')"  style="width: 100%; float: none; white-space: nowrap; align-items: center; display: flex; margin-top: 10px;">
                    <mat-icon fontIcon="cloud_download" style="color: gray; cursor: pointer; margin-right: 5px; font-size: 20px; width: fit-content; height: fit-content;"></mat-icon>
                    <div>Back Up Projects Data</div>
                </button>
                <button type="button" class="button-secondary-small" (click)="backUpServerData('content')"  style="width: 100%; float: none; white-space: nowrap; align-items: center; display: flex; margin-top: 10px;">
                    <mat-icon fontIcon="cloud_download" style="color: gray; cursor: pointer; margin-right: 5px; font-size: 20px; width: fit-content; height: fit-content;"></mat-icon>
                    <div>Back Up Contents</div>
                </button>
                <button type="button" class="button-secondary-small" (click)="backUpServerData('database')"  style="width: 100%; float: none; white-space: nowrap; align-items: center; display: flex; margin-top: 10px;">
                    <mat-icon fontIcon="cloud_download" style="color: gray; cursor: pointer; margin-right: 5px; font-size: 20px; width: fit-content; height: fit-content;"></mat-icon>
                    <div>Back Up Database</div>
                </button>
            </div>
        </div> -->
    </div>
</div>