<div>
    <div style="width: 100%; height: 30px; background-color: var(--primary); border-bottom: 3px solid #9DB2BF; ">
        <div class="container" style="max-width: 100% !important; color: white;">
            <div fxLayout="row">
                <div class="center-vertical" class="profile" fxFlex="10%"><img src="assets/logos/icon_enovsky.png"></div>
                <div class="center-vertical" fxFlex="30%" style="white-space: nowrap;">
                    {{taskLabels[currentTask]}}
                </div>
                <div class="center-vertical" fxFlex="40%">
                    <nav style="z-index: 10000;">
                        <ul style="margin: 0; display: flex;" fxLayoutAlign="center center"> 
                            <li> <mat-icon (click)="splittingModule.showSplitting()" style="cursor: pointer; margin-right: 20px; font-size: 20px;" [ngClass]="splittingActive? 'page-active': 'page-inactive'" aria-hidden="false" fontIcon="content_cut"></mat-icon>
                                <ul> <li>Split</li></ul> 
                            </li>
                            <li> <mat-icon (click)="splittedModule.showSplitted()" style="cursor: pointer; margin-right: 20px;" [ngClass]="splittedActive? 'page-active': 'page-inactive'" aria-hidden="false" fontIcon="horizontal_split"></mat-icon>
                                <ul> <li>Splitted</li></ul> 
                            </li> 
                             
                            <li> <mat-icon (click)="cleanedModule.showCleaned()" style="cursor: pointer; margin-right: 20px; font-size: 20px" [ngClass]="cleanedActive? 'page-active': 'page-inactive'" aria-hidden="false" fontIcon="cleaning_services"></mat-icon>
                                <ul> <li>Cleaned</li></ul> 
                           </li>
                           <li> <mat-icon (click)="classifyModule.showClassified()" style="cursor: pointer; margin-right: 20px;" [ngClass]="classifiedActive? 'page-active': 'page-inactive'" aria-hidden="false" fontIcon="hive"></mat-icon>
                                <ul> <li>Classified</li></ul>     
                            </li> 
                            <li *ngIf="userInfos['privilege'] >= 3"> <mat-icon style="color: white; cursor: pointer; margin-right: 20px; font-size: 20px;" aria-hidden="false" fontIcon="more_vert" title="Settings"></mat-icon>
                                <ul style="right: -4px;"> 
                                    <li (click)="classifyModule.deleteProject('delete_project_without_ressource')" style="white-space: nowrap; display: flex; ">
                                        <div>Delete Project withtout Ressources</div>
                                    </li>
                                    <li (click)="classifyModule.deleteProject('delete_project_with_ressource')" style="white-space: nowrap; display: flex;">
                                        <div>Delete Project with Ressources</div>
                                    </li>
                                </ul> 
                            </li> 
                        </ul> 
                    </nav>
                </div>
                <div fxFlex="20%" style="display: flex; align-items: center;">
                    <div style="margin-left: auto; display: flex;">
                        <mat-icon *ngIf="splittingActive || splittedActive" (click)="buildProcess()" style="color: white; cursor: pointer; margin-right: 40px;" aria-hidden="false" fontIcon="settings" title="Build"></mat-icon>
                        <mat-icon style="color: white; cursor: pointer;" aria-hidden="false" fontIcon="close" title="Close" (click)="onClose()"></mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <mat-progress-bar *ngIf="inProgress" class="progress_bar" mode="buffer"></mat-progress-bar>
        
    </div>
    <div *ngIf="splittingActive" class="show_content resizable-container-vertical">
        <div class="content_to_split resizable-zone-vertical" id="left-vertical">
            <div fxLayout="row" style="background-color: #446994;">
                <nav style="z-index: 10000;">
                    <ul style="margin: 0;" fxLayoutAlign="center center"> 
                        <li> <mat-icon (click)="splittingModule.expandCropSplitting()" style="cursor: pointer; margin-right: 20px; font-size: 20px;" [ngClass]="splittingActive? 'page-active': 'page-inactive'" aria-hidden="false" fontIcon="vertical_split"></mat-icon>
                            <ul> <li style="white-space: nowrap;">Expand First Crop</li></ul> 
                        </li>
                    </ul> 
                </nav>
            </div>
            <div class="show_content_to_split"> </div>
        </div>
        <div class="resizable-bar-vertical" id="drag-vertical"></div>
        <div class="show_content_split_resume resizable-zone-vertical" id="right-vertical"></div>
    </div>
    <div *ngIf="splittedActive" class="pages show_content_splited" style="overflow: auto;"> </div>
    <div *ngIf="cleanedActive" class="pages show_content_cleaned" style="overflow: auto;"> </div>
    <div *ngIf="classifiedActive">
        <app-project-classify [userInfos]="userInfos" [classifyData]="classifyData" [projectInfos]="projectInfos" [platformInfos]="platformInfos"></app-project-classify>
    </div>
</div>