import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Component, Inject, Renderer2, ElementRef, ChangeDetectorRef} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { DialogComponent } from 'src/app/back-office/components/dialog/dialog.component';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import config from 'config/default.json';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class SplittingModule { 
  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    private cdRef: ChangeDetectorRef,
    private helper: HelperModule,
    private apiService: ApiServiceModule,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    ) {}
    
    
  that: any;
  splittedModule: any;
  ctx!: CanvasRenderingContext2D;
  pageHeaderString!: SafeHtml;
  pdfContainer: any;
  pdfValidationContainer: any;
  resizableDivs!: any;
  

  dataValidation: {[x: string]: any} = {};
  contentsInfos:  {[x: string]: any} = {};

  splitInProcess = false;
  showResult = false
  
  defaultWidth = 300;
  defautlHeight = 450;
  defaultTop = 100;
  defaultLeft = 50;

  init(that:any, splittedModule:any){
    this.that = that;
    this.splittedModule = splittedModule;
    
  }
  showSplitting(){
    if(this.that.rawData.length){
      console.log(this.that.rawData)
      this.that.splitedActive = false;
      this.that.cleanedActive = false;
      this.that.splitingActive = true;
      this.that.activatePage('task-splitting');
      this.that.projectInfos['last_task'] = 'task-splitting';
      this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/project/save_current_task', {id_project: this.that.projectInfos['id'], cuurent_task: 'task-splitting', platform_infos: this.that.platformInfos}).subscribe();
      this.pdfContainer = this.elRef.nativeElement.querySelector('.show_content_to_split');
      this.pdfValidationContainer = this.elRef.nativeElement.querySelector('.show_content_split_resume');
      
      this.showPages(); 
      this.reorderValidation()
      this.prepareExpandableSpace();
    }else this.helper.showToast('No content selected...');

    
  }
  onDrop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.resizableDivs, event.previousIndex, event.currentIndex);
  }
  computeSplit(){
    if(this.that.rawData.length){
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '50%',
        position: {top: '30px' },
        data: {page: 'confirmation', text: 'This action will erase your previous Split Build... Continue ?'}
      });
      dialogRef.afterClosed().subscribe((toContinue:boolean) => {
        if(toContinue){
          this.that.inProgress = true;
          let contents = this.extractContent();
          let contentSave = this.extractContentSave(contents);
          let selectedContents:any = {};
          this.that.rawData = this.that.rawData.map((element:any)=>{
            selectedContents[element.id] = element;
            element.split_data = contentSave[element.id];
            return element;
          })
          this.contentsInfos = {
            platform_infos: this.that.platformInfos,
            user_infos: this.that.userInfos,
            project_infos: this.that.projectInfos,
            splitted_content: contents, 
            split_data: contentSave,
            raw_content: selectedContents
          };
          const formData = new FormData();
          formData.append('data', JSON.stringify(this.contentsInfos));

          this.apiService.queryData(config.master.flapi.url + '/enovsky/split_content', formData).subscribe((response:any)=>{
            this.that.inProgress = false;
            if(response.status){
              this.that.contentData = response.data;
      
              //this.that.activatePage('task-splitted');
      
              this.splittedModule.showSplitted();
              //this.that.cleanActive = true;
            }else this.helper.showToast(response.message);
          }) 
        }
      })
      
    }
    else this.helper.showToast('Split content not created...');
    
  }
  extractContent(){
    let validations = this.pdfValidationContainer.querySelectorAll('.crop_validation');
    let contents = []
    let content:any = []
    
    validations.forEach((validation:any, order:number)=>{
      let num_doc = parseInt(validation.getAttribute('num_doc'));
      let num_page = parseInt(validation.getAttribute('num_page'));
      let num_crop = parseInt(validation.getAttribute('num_crop'));
      let isSeparator = validation.getAttribute('sep');
      let isAutoSized = validation.getAttribute('is_auto_sized');

      let cropInfos = this.dataValidation['doc_'+num_doc+'_page_'+num_page].find((e:any) => e.num_crop == num_crop);
      cropInfos['end'] = isSeparator == 'yes'? true: false;
      cropInfos['order'] = order;
      cropInfos['id'] = cropInfos['raw_id']+'-'+cropInfos['num_page']+'-'+cropInfos['num_crop'];
      cropInfos['is_auto_sized'] = isAutoSized;

      content.push(cropInfos);
      if(isSeparator == 'yes'){
        contents.push(content);
        content = [];
      }
    });
    if(content.length > 0) contents.push(content);
    return contents;
  }

  transform (value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
  removeElementNode(element:any){
    while (element.hasChildNodes()) {
      element.removeChild(element.lastChild);
    }
    element.remove()
  }
  async showPages(){
    this.that.inProgress = true;
    let filesPath:any = {}
    this.that.rawData.forEach((raw:any)=> {filesPath[raw.id] = this.that.platformInfos['root']+'/'+ raw.file_path+'.pdf'});
    let data = {
      table: 'generate_files_urls',
      files_path: filesPath
    }
    //Get file
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    this.apiService.queryData(config.service.cloud.url + '/generate_files_urls', formData).subscribe(async(response:any)=>{
      this.that.PDFJS.GlobalWorkerOptions.workerSrc = 'assets/pdf/pdf.worker.js';
      let totalDocuments = this.that.rawData.length;
      for(let index = 0; index < totalDocuments; index++){
        let documentId = this.that.rawData[index]['id'];
        let documentCrops = this.that.rawData[index]['split_data'];
        let fileUrl = response[documentId].file_url;
          //Show PDF
        try {
          const pdf = await this.that.PDFJS.getDocument(fileUrl).promise;
          const totalPages = pdf.numPages;
          for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
            let that = this;
            let pageCrops = documentCrops? documentCrops[pageNumber]: null;
            const page = await pdf.getPage(pageNumber);
            //Icons
            let rotateIcon = that.renderer.createElement('mat-icon');
            let rotation = documentCrops && pageCrops && pageCrops.length? Math.abs(pageCrops[0]['rotation']): 0;
            const angle = 90;

            rotateIcon.addEventListener('click', async(e:any) =>{
              rotation = (rotation + angle) % 360;
              await that.buildPage(that, page, pageCrops, -rotation, rotateIcon, documentId, index + 1, pageNumber); 
            })
            await that.buildPage(that, page, pageCrops, -rotation, rotateIcon, documentId, index + 1, pageNumber);
            
            if (index == totalDocuments - 1 && pageNumber == totalPages) this.that.inProgress = false;
          }
          
        }catch (error) {
          // Handle errors here
          console.error(error);
        }
      }
    })
  }

  getCursorPosition(canvas:any, event:any) {
    const rect = canvas.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top
    return {'x':x, 'y':y}
  }

 async  buildPage(that:any, page:any, pageCrops:any, rotation:number, rotateIcon:any, documentId:any,  documentNumber:any, pageNumber:any){
    //Prepare Canvas
    var scale = 1;
    
    var viewport = page.getViewport({ scale: scale, rotation: rotation });
    var canvas = this.renderer.createElement('canvas');
    var context = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    let canvasDiv = this.renderer.createElement('div');
    canvasDiv.style.height = viewport.height+'px';
    canvasDiv.classList.add("canvasDiv");
    
    canvasDiv.style.overflow = "scroll";  // Allow scrolling
    canvasDiv.style.scrollbarWidth = "none"; // Hide scrollbar (Firefox)
    canvasDiv.style.msOverflowStyle = "none"; 

    canvas.height = viewport.height;
    canvas.width = viewport.width;
    canvas.style.position = "absolute";

    //Header
    let canvasDivParent = that.renderer.createElement('div');
    let header = that.renderer.createElement('div');
    let middleSide = that.renderer.createElement('div');
    let leftSide = that.renderer.createElement('div');
    let rightSide = that.renderer.createElement('div');

    header.classList.add("page_header");
    leftSide.classList.add("page_header_left");
    middleSide.classList.add("page_header_middle");
    rightSide.classList.add("page_header_right");

    rotateIcon = that.iconProperties(rotateIcon, 'rotate_left', 'rotate');

    let cropIcon = that.renderer.createElement('mat-icon');
    cropIcon = that.iconProperties(cropIcon, 'crop', 'Add crop');

    let updateIcon = that.renderer.createElement('mat-icon');
    updateIcon = that.iconProperties(updateIcon, 'done', 'Update');

    leftSide.textContent = 'Content: '+documentNumber+' - Page: '+pageNumber+' ('+documentId+')';
    
    var renderContext = { canvasContext: context, viewport: viewport };
    await page.render(renderContext).promise;

    middleSide.append(cropIcon, rotateIcon);
    rightSide.append(updateIcon);
    header.append(leftSide, middleSide, rightSide);
    canvasDiv.append(canvas);
    canvasDivParent.append(header, canvasDiv);
    let pageIdentifier = 'doc_'+documentNumber+'_page_'+pageNumber;
    canvasDivParent.classList.add(pageIdentifier);
    let oldCanvasDivParent = that.pdfContainer.querySelector('.'+pageIdentifier);

    if(oldCanvasDivParent){
      let previousCanvasDivParent = oldCanvasDivParent.previousSibling;
      let nextCanvasDivParent = oldCanvasDivParent.nextSibling;
      that.removeElementNode(oldCanvasDivParent);

      if(!previousCanvasDivParent && nextCanvasDivParent) 
        nextCanvasDivParent.parentNode.insertBefore(canvasDivParent, nextCanvasDivParent);
      else if(!nextCanvasDivParent)
        that.pdfContainer.appendChild(canvasDivParent);
      else previousCanvasDivParent.parentNode.insertBefore(canvasDivParent, previousCanvasDivParent.nextSibling);
    }else that.pdfContainer.appendChild(canvasDivParent);

    let numCropper = 1;
    if(pageCrops){
      pageCrops.forEach((crop:any)=>{
        that.addCropperResizePage(canvasDiv, crop.num_crop, documentNumber+'-'+crop.num_page, crop.is_auto_sized ?? true, crop.crop_width, crop.crop_height, crop.crop_y, crop.crop_x, crop.order, crop.end);
        
      })
      that.validation(canvas, canvasDiv, rotation, documentId, documentNumber, pageCrops[0].num_page, pageCrops[0]?.is_auto_sized ?? true, pageCrops);
      that.sortValidation()
    }else{
      that.addCropperResizePage(canvasDiv, numCropper, documentNumber+'-'+pageNumber);
      that.validation(canvas, canvasDiv, rotation, documentId, documentNumber, pageNumber);
    }
    cropIcon.addEventListener('click', function(e:any) {
      numCropper += 1;
      that.addCropperResizePage(canvasDiv, numCropper, documentNumber+'-'+pageNumber);
    });
    
    updateIcon.addEventListener('click', async function(e:any) {
      that.that.inProgress = false;
      
      await that.validation(canvas, canvasDiv, rotation, documentId, documentNumber, pageNumber, false);
      let contents = that.extractContent();
      let contentSave = that.extractContentSave(contents);
      let saveData = {
        platform_infos: that.that.platformInfos,
        project_infos: that.that.projectInfos,
        user_infos: that.that.userInfos,
        split_data: contentSave
      };
      
      that.apiService.queryData(config.master.ndapi.url + '/enovsky/web/project/save_split_data', saveData).subscribe((response:any)=>{
        that.that.inProgress = false;
        that.helper.showToast(response.message);
      }) 
    });
    
  }
  extractContentSave(contents:any){
    const contentSave:any = {};
    contents.flat().forEach((crop:any) => {
      const rawId = crop.raw_id;
      const numPage = crop.num_page;
      if (!contentSave[rawId]) contentSave[rawId] = {};
      if (!contentSave[rawId][numPage]) contentSave[rawId][numPage] = [];
      contentSave[rawId][numPage].push(crop);
    });
    return contentSave;
  }

  iconProperties(icon:any, iconName:string, title:string){
    let attributes = {
      fonticon: iconName,
      title: title,
      'ng-reflect-font-icon': iconName,
      'data-mat-icon-type': 'font',
      'data-mat-icon-name': iconName,
      'aria-hidden': 'false'
    }
    for (const [key, value] of Object.entries(attributes)) {
      icon.setAttribute(key, value);
    }
    icon.classList.add("bx", "bx-menu", "page_icons", 'mat-icon','notranslate','material-icons','mat-ligature-font','mat-icon-no-color');
    return icon
  }
  
addCropperResizePage(canvasDiv:any, numCropper:number, index:string, isAutoSized = true, width:number = this.defaultWidth, height:number = this.defautlHeight, top:number = this.defaultTop, left:number = this.defaultLeft, order:number = 0, end:boolean = false){
    let resizableDiv = this.renderer.createElement('div');
    resizableDiv.style.width = width+"px";
    resizableDiv.style.height = height+"px";
    resizableDiv.style.top = top+"px";
    resizableDiv.style.left = left+"px";
    resizableDiv.classList.add("resizableDiv");
    canvasDiv.appendChild(resizableDiv);

    let resizerTopLeft = this.renderer.createElement('div');
    let resizerTopRight = this.renderer.createElement('div');
    let resizerBottomLeft = this.renderer.createElement('div');
    let resizerBottomRight = this.renderer.createElement('div');
    let resizerTop = this.renderer.createElement('div');
    let resizerLeft = this.renderer.createElement('div');
    let resizerRight = this.renderer.createElement('div');
    let resizerBottom = this.renderer.createElement('div');

    resizerTopLeft.classList.add('resizer','corner', 'top-left');
    resizerTopRight.classList.add('resizer','corner', 'top-right');
    resizerBottomLeft.classList.add('resizer','corner', 'bottom-left');
    resizerBottomRight.classList.add('resizer','corner', 'bottom-right');
    resizerTop.classList.add('resizer','side', 'top');
    resizerLeft.classList.add('resizer','side', 'left');
    resizerRight.classList.add('resizer','side', 'right');
    resizerBottom.classList.add('resizer','side', 'bottom');

    resizableDiv.setAttribute("num_crop", numCropper);
    resizableDiv.setAttribute("order_crop", order);
    resizableDiv.setAttribute("end_crop", end);
    resizableDiv.setAttribute("is_auto_sized", isAutoSized);
    resizableDiv.append(resizerTopLeft, resizerBottomLeft, resizerTopRight, resizerBottomRight, resizerTop, resizerLeft, resizerRight, resizerBottom);
    this.applyResize(canvasDiv, resizableDiv)
    this.makeDragg(canvasDiv, resizableDiv, index);

    //MENUS
    let contextMenu = this.renderer.createElement('div');
    let deleteResizer = this.renderer.createElement('div');

    contextMenu.classList.add('context-menu');
    deleteResizer.textContent = 'Remove cropper';
    deleteResizer.classList.add('item');
    contextMenu.append(deleteResizer);

    resizableDiv.addEventListener("contextmenu", (e:any) => {
      e.preventDefault();
      contextMenu.style.top = `${e.clientY}px`;
      contextMenu.style.left = `${e.clientX}px`;
      contextMenu.style.display = "block";
    })
    resizableDiv.addEventListener("click", (e:any) => {
      if (e.target.offsetParent != contextMenu) {
        contextMenu.style.display = "none";
      }
    });
    deleteResizer.addEventListener("click", (e:any) => {
      this.removeElementNode(resizableDiv);
      contextMenu.style.display = "none";
    });
    resizableDiv.append(contextMenu);

  }
  applyResize(canvasDiv:any, resizableDiv:any){
    let startMouseX = 0;
    let startMouseY = 0;
    let startWidth = 0;
    let startHeight = 0;
    let initialOffsetTop = 0;
    let initialOffsetLeft = 0;
    const resizers = resizableDiv.querySelectorAll('.resizer');

    for (let i = 0;i < resizers.length; i++) {
      const currentResizer = resizers[i];
      currentResizer.addEventListener('mousedown', (e:any)=> {
        
        e.preventDefault();
        startMouseX = e.clientX;
        startMouseY = e.clientY;
        startWidth = resizableDiv.offsetWidth;
        startHeight = resizableDiv.offsetHeight;
        initialOffsetTop = resizableDiv.offsetTop;
        initialOffsetLeft = resizableDiv.offsetLeft;
        canvasDiv.addEventListener("mousemove", resize);
        canvasDiv.addEventListener("mouseup", stopResize);
      })
      function resize(e:any) {
        if (currentResizer.classList.contains('top')) {
          const newHeight = startHeight - (e.clientY - startMouseY);
          const newOffsetTop = initialOffsetTop + (e.clientY - startMouseY);
          resizableDiv.style.height = `${newHeight}px`;
          resizableDiv.style.top = `${newOffsetTop}px`;

        }else if (currentResizer.classList.contains('left')) {
          const newWidth = startWidth - (e.clientX - startMouseX);
          const newOffsetLeft = initialOffsetLeft + (e.clientX - startMouseX);
          resizableDiv.style.width = `${newWidth}px`;
          resizableDiv.style.left = `${newOffsetLeft}px`;

        }else if (currentResizer.classList.contains('right')) {
          const newWidth = startWidth + (e.clientX - startMouseX);
          resizableDiv.style.width = `${newWidth}px`;

        }else if (currentResizer.classList.contains('bottom')) {
          const newHeight = startHeight + (e.clientY - startMouseY);
          resizableDiv.style.height = `${newHeight}px`;

        } else if (currentResizer.classList.contains('top-left')){
          const newHeight = startHeight - (e.clientY - startMouseY);
          const newOffsetTop = initialOffsetTop + (e.clientY - startMouseY);
          const newWidth = startWidth - (e.clientX - startMouseX);
          const newOffsetLeft = initialOffsetLeft + (e.clientX - startMouseX);
          resizableDiv.style.width = `${newWidth}px`;
          resizableDiv.style.left = `${newOffsetLeft}px`;
          resizableDiv.style.height = `${newHeight}px`;
          resizableDiv.style.top = `${newOffsetTop}px`;

        }else if (currentResizer.classList.contains('top-right')) {
          const newHeight = startHeight - (e.clientY - startMouseY);
          const newOffsetTop = initialOffsetTop + (e.clientY - startMouseY);
          const newWidth = startWidth + (e.clientX - startMouseX);
          resizableDiv.style.width = `${newWidth}px`;
          resizableDiv.style.height = `${newHeight}px`;
          resizableDiv.style.top = `${newOffsetTop}px`;

        }else if (currentResizer.classList.contains('bottom-left')) {
          const newWidth = startWidth - (e.clientX - startMouseX);
          const newOffsetLeft = initialOffsetLeft + (e.clientX - startMouseX);
          const newHeight = startHeight + (e.clientY - startMouseY);
          resizableDiv.style.height = `${newHeight}px`;
          resizableDiv.style.width = `${newWidth}px`;
          resizableDiv.style.left = `${newOffsetLeft}px`;

        }else if (currentResizer.classList.contains('bottom-right')) {
          const newWidth = startWidth + (e.clientX - startMouseX);
          const newHeight = startHeight + (e.clientY - startMouseY);
          resizableDiv.style.height = `${newHeight}px`;
          resizableDiv.style.width = `${newWidth}px`;
        }
        
      }
  
      function stopResize() {
          canvasDiv.removeEventListener("mousemove", resize);
          canvasDiv.removeEventListener("mouseup", stopResize);
      }
    }
    
  }

  makeDragg(canvasDiv:any, resizableDiv:any, index:string){

    let minDreagg = 20;
    let toDragg = false;
    var dragOffsetX:any;
    var dragOffsetY:any;

    resizableDiv.addEventListener('mousedown', startDrag);
    function startDrag(event:any) {
      dragOffsetX = event.clientX - resizableDiv.offsetLeft;
      dragOffsetY = event.clientY - resizableDiv.offsetTop;
      toDragg = event.offsetX > minDreagg && event.offsetY > minDreagg && resizableDiv.offsetWidth - event.offsetX > minDreagg && resizableDiv.offsetHeight - event.offsetY > minDreagg;
      if(toDragg){
        canvasDiv.addEventListener('mousemove', drag);
      }
    }

    
    function drag(event:any) {
      
      if (dragOffsetX && dragOffsetY && toDragg) {
        var newLeft = event.clientX - dragOffsetX;
        var newTop = event.clientY - dragOffsetY;

        var containerRect = canvasDiv.getBoundingClientRect();
        var movableRect = resizableDiv.getBoundingClientRect();

        var leftLimit = containerRect.left;
        var rightLimit = containerRect.right - movableRect.width;
        var topLimit = containerRect.top;
        var bottomLimit = containerRect.bottom - movableRect.height;

        // Restrict movable within the container
        let draggableX = newLeft >= leftLimit && newLeft <= rightLimit;
        let draggableY = newTop >= topLimit && newTop <= bottomLimit;
        resizableDiv.style.left = newLeft + 'px';
        resizableDiv.style.top = newTop + 'px';
      }
    }
    resizableDiv.addEventListener('mouseup', endDrag);
    function endDrag() {
      dragOffsetX = null;
      dragOffsetY = null;
      toDragg = false;
    }
  }
  prepareExpandableSpace(){
    let nativeElement = this.elRef.nativeElement;
    const leftVertical = nativeElement.querySelector('#left-vertical');
    const rightVertical = nativeElement.querySelector('#right-vertical');
    const dragVertical = nativeElement.querySelector('#drag-vertical');
    let startLeftWidth:any;
    let startRightWidth:any;

    const onMouseMoveVertical = (e:any) => {
      const deltaX = e.clientX - startLeftWidth;
      const newLeftWidth = startLeftWidth + deltaX;
      const newRightWidth = startRightWidth - deltaX;

      if (newLeftWidth >= 0 && newRightWidth >= 0) {
        leftVertical.style.width = newLeftWidth + 'px';
        rightVertical.style.width = newRightWidth + 'px';
      }
    };
    const onMouseUp = () => {
      nativeElement.removeEventListener('mousemove', onMouseMoveVertical);
      nativeElement.removeEventListener('mouseup', onMouseUp);
    };
    dragVertical.addEventListener('mousedown', (e:any) => {
      console.log(e)
      startLeftWidth = leftVertical.getBoundingClientRect().width;
      startRightWidth = rightVertical.getBoundingClientRect().width;
      nativeElement.addEventListener('mousemove', onMouseMoveVertical);
      nativeElement.addEventListener('mouseup', onMouseUp);
    });
    
  }

  validation(canvas:any, canvasDiv:any, rotation:number, contentId:number, documentNumber:number, pageNumber:number, isAutoSized = true, pageCrops:any){
    let previousValidation:any = null;
    let nextValidation:any = null;
    let pageIdentifier = 'doc_'+documentNumber+'_page_'+pageNumber;
    let oldValidations = this.pdfValidationContainer.querySelectorAll('.'+pageIdentifier);
    
    this.resizableDivs = canvasDiv.querySelectorAll('.resizableDiv');

    if(oldValidations.length){
      previousValidation = oldValidations[0].previousSibling;
      nextValidation = [...oldValidations].at(-1).nextSibling;
      oldValidations.forEach((element:any)=>{
        this.removeElementNode(element);
      })
    }
    let crops:any = [];
    this.resizableDivs.forEach((resizableDiv: any)=>{
      let canvasDivParentValidation = this.renderer.createElement('div');
      let canvasDivValidation= this.renderer.createElement('div');
      let header= this.renderer.createElement('div');

      let cropIndex = parseInt(resizableDiv.getAttribute('num_crop'));
      let cropOrder = parseInt(resizableDiv.getAttribute('order_crop'));
      let cropEnd = resizableDiv.getAttribute('end_crop');


      var cropX = resizableDiv.offsetLeft ;
      var cropY = resizableDiv.offsetTop;
      var cropWidth = resizableDiv.offsetWidth - 6;
      var cropHeight = resizableDiv.offsetHeight - 6;

      var canvas1 = this.renderer.createElement('canvas');
      canvas1.width = cropWidth;
      canvas1.height = cropHeight;
      var ctx1 = canvas1.getContext('2d');
      ctx1.drawImage(canvas, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

      
      crops.push({raw_id: contentId, num_page: pageNumber, num_crop: cropIndex, rotation: rotation, crop_x: cropX, crop_y: cropY, crop_width: cropWidth, crop_height: cropHeight, page_width: canvasDiv.offsetWidth, page_height: canvasDiv.offsetHeight});
      header.classList.add("valid_header");
      header.textContent = 'Content: '+documentNumber+' - Page: '+pageNumber+' - Crop: '+cropIndex;
      let cropIdentifier = 'doc_'+documentNumber+'_page_'+pageNumber+'_crop_'+cropIndex;
      canvasDivParentValidation.classList.add("crop_validation");
      canvasDivParentValidation.classList.add(pageIdentifier);
      canvasDivParentValidation.setAttribute("num_crop", cropIndex);
      canvasDivParentValidation.setAttribute("num_page", pageNumber);
      canvasDivParentValidation.setAttribute("num_doc", documentNumber);
      canvasDivParentValidation.setAttribute("crop_id", cropIdentifier);
      canvasDivParentValidation.setAttribute("sep", 'no');
      canvasDivParentValidation.setAttribute("is_auto_sized", isAutoSized);
      canvasDivParentValidation.style.width = '100%';
      resizableDiv.setAttribute("is_auto_sized", isAutoSized);

      let line = this.renderer.createElement("div");
      line.classList.add("line_separator");
      if(cropEnd == 'true'){
        line.style.borderTop = "3px dashed #116A7B";
        canvasDivParentValidation.setAttribute("sep", 'yes');
      }
      canvasDivParentValidation.setAttribute("order", cropOrder);
      line.addEventListener('click', function(e:any) {
          let isSeparator = canvasDivParentValidation.getAttribute('sep');
          if(isSeparator == 'yes'){
            line.style.borderTop = "none";
            canvasDivParentValidation.setAttribute("sep", 'no');
          }else{
            line.style.borderTop = "3px dashed #116A7B";
            canvasDivParentValidation.setAttribute("sep", 'yes');
          }
          
      });
      canvasDivValidation.appendChild(canvas1);
      canvasDivValidation.style.overflow = "scroll";  // Allow scrolling
      canvasDivValidation.style.scrollbarWidth = "none"; // Hide scrollbar (Firefox)
      canvasDivValidation.style.msOverflowStyle = "none"; 

      //canvasDivValidation.classList.add("canvasDivContainerSplittingPreview");
      
      canvasDivParentValidation.append(header, canvasDivValidation, line);
      canvasDivParentValidation.setAttribute("draggable", true);
      if(oldValidations.length){
        if(!previousValidation && nextValidation) 
          nextValidation.parentNode.insertBefore(canvasDivParentValidation, nextValidation);
        else if(!nextValidation){
          //canvasDivParentValidation.setAttribute("draggable", true);
          this.pdfValidationContainer.appendChild(canvasDivParentValidation);
        }
        else previousValidation.parentNode.insertBefore(canvasDivParentValidation, previousValidation.nextSibling);
        previousValidation = canvasDivParentValidation;
      }else {
        
        this.pdfValidationContainer.appendChild(canvasDivParentValidation);
      }
      
    });
    this.dataValidation['doc_'+documentNumber+'_page_'+pageNumber] = crops;
    
  }

  sortValidation(){
    const divElements = Array.from(this.pdfValidationContainer.querySelectorAll('.crop_validation'));
    divElements.sort((a:any, b:any) => {
        const orderA = parseInt(a.getAttribute('order'));
        const orderB = parseInt(b.getAttribute('order'));
        return orderA - orderB;
    });

    // Clear the container
    this.pdfValidationContainer.innerHTML = '';
    divElements.forEach((divElement) => {
      this.pdfValidationContainer.appendChild(divElement);
    });
  }

  reorderValidation(){
    let draggingElement!: any;
    this.pdfValidationContainer.addEventListener("dragstart", (event:any) => {
      draggingElement = event.target;
      console.log('startt')
      event.dataTransfer.setData("text/plain", "");
    });

    this.pdfValidationContainer.addEventListener("dragover", (event:any) => {
      event.preventDefault();

      const mouseY = event.clientY;
      const containerRect = this.pdfValidationContainer.getBoundingClientRect();
      const containerScrollTop = this.pdfValidationContainer.scrollTop;

      if (mouseY < containerRect.top + 30 && containerScrollTop > 0) {
        this.pdfValidationContainer.scrollTop -= 10;
      } else if (mouseY > containerRect.bottom - 30 && containerScrollTop < this.pdfValidationContainer.scrollHeight - this.pdfValidationContainer.clientHeight) {
        this.pdfValidationContainer.scrollTop += 10;
      }
    });

    this.pdfValidationContainer.addEventListener("drop", (event:any) => {
      event.preventDefault();
      let dropIndex = -1;
      let element = event.target;

      while (element && !element.classList.contains("crop_validation")) {
        element = element.parentElement;
      }
      if (element && element.classList.contains("crop_validation")) {
        dropIndex = Array.from(document.querySelectorAll(".crop_validation")).indexOf(element);
      }
      const parent = draggingElement.parentNode;
      if (dropIndex > -1 && parent) {
        parent.removeChild(draggingElement);
        const items = Array.from(this.pdfValidationContainer.querySelectorAll(".crop_validation"));
        items.splice(dropIndex, 0, draggingElement);
        items.forEach(item => parent.appendChild(item));
      }
      draggingElement = null;
    });
  }

  expandCropSplitting(){
    
    /* let allResizableDiv  = this.pdfContainer.querySelectorAll('.resizableDiv');

    if(allResizableDiv.length){
      for(let resisableDiv of allResizableDiv){
        if(resisableDiv.getAttribute('is_auto_sized') == 'true'){
          this.removeElementNode(resisableDiv);
        }
      }
    } */
  }
  
}
