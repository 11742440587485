<div>
    <div class="head-title">
        <div class="left">
          <h3>{{projectInfos['belongs'].join(' - ')}} ({{projectInfos['id_structure']}})</h3>
        </div>
    </div>
    <div style="text-align:left; padding: 20px;">
        <input class="float-right col-input" style="width: 180px; float:right;" (keyup)="applyFilterLocal($event.target, 'all_column')" placeholder="Search">
        <p>Total: {{this.showedRows.length}}</p>
    </div>
    <div *ngIf="platformInfos['origin'] == 'virtualcampus'" class="table_div">
        <table>
            <thead>
            <tr>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'id_project', 'number')"></mat-icon>
                    <div class="column-name">Id Project</div>
                    <input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'id_project')">
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'id_content', 'number')"></mat-icon>
                    <div class="column-name">Id Content</div>
                    <input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'id_content')">
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'department', 'string')"></mat-icon>
                    <div class="column-name">Department</div>
                    <input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'department')">
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'semester', 'string')"></mat-icon>
                    <div class="column-name">Semester</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'semester')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'module', 'string')"></mat-icon>
                    <div class="column-name">Module</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'module')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'year', 'string')"></mat-icon>
                    <div class="column-name">Year</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'year')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'section', 'string')"></mat-icon>
                    <div class="column-name">Section</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'section')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'type', 'string')"></mat-icon>
                    <div class="column-name">Type</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'type')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'state', 'string')"></mat-icon>
                    <div class="column-name">State</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'state')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'active', 'string')"></mat-icon>
                    <div class="column-name">Active</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'active')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'source', 'string')"></mat-icon>
                    <div class="column-name">Source</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'source')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'status', 'string')"></mat-icon>
                    <div class="column-name">Status</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'status')"></div>
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody #row_tbody *ngFor="let row of showedRows; let i = index">
                <tr (click)="toggleRow(row, $event.target, row_tbody)" [ngClass]="{'highlight-previous': row['source'] !='project'}">
                    <td>{{ row['id_project'] }}</td>
                    <td>{{ row['id_content'] }}</td>
                    <td>{{ row['department'] }}</td>
                    <td>{{ row['semester'] }}</td>
                    <td>{{ row['module'] }}</td>
                    <td style="white-space: nowrap;">{{ row['year'] }}</td>
                    <td>{{ row['section'] }}</td>
                    <td >{{ row['type']}}</td>
                    <td [innerHTML]="stateTagsDisplay(row['state'])"></td>
                    <td [innerHTML]="stateTagsDisplay(row['active'])"></td>
                    <td >{{ row['source']}}</td>
                    <td >{{ row['status']}}</td>
                    <td class="to_skip_element select_container">
                        <div *ngIf="row['nb_duplicate'] > 1 && row['source'] == 'project'" class="duplicate-mark"></div>
                        <mat-icon *ngIf="row['source']=='project'"  sorted="" fontIcon="more_vert" style="color: gray; font-size: 20px;" (click)="contentOption(row)"></mat-icon>
                    </td>
                </tr>
                <tr class="expand_detail" *ngIf="row['expanded']">
                    <td colspan="14">
                        <app-vca-detail-content [rowInfos]="row" [userInfos]="userInfos" [projectInfos]="projectInfos" (inProgress)="setWaiting($event)" (updateStates)="setUpdateStates($event)" (updateContent)="setUpdatedContent($event)"></app-vca-detail-content>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="showedRows.length === 0">
                <tr ><td colspan="14" style="text-align: center;">No matching records found</td></tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="platformInfos['origin'] == 'virtualclass'" class="table_div">
        <table>
            <thead>
            <tr>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'id_project', 'number')"></mat-icon>
                    <div class="column-name">Id Project</div>
                    <input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'id_project')">
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'id_content', 'number')"></mat-icon>
                    <div class="column-name">Id Content</div>
                    <input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'id_content')">
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'belongs', 'string')"></mat-icon>
                    <div class="column-name">Belongs to</div>
                    <input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'belongs')">
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'class', 'string')"></mat-icon>
                    <div class="column-name">Class</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'class')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'study', 'string')"></mat-icon>
                    <div class="column-name">Study</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'study')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'module', 'string')"></mat-icon>
                    <div class="column-name">Module</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'module')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'year', 'string')"></mat-icon>
                    <div class="column-name">Year</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'year')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'period', 'string')"></mat-icon>
                    <div class="column-name">Period</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'period')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'section', 'string')"></mat-icon>
                    <div class="column-name">Section</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'section')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'type', 'string')"></mat-icon>
                    <div class="column-name">Type</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'type')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'state', 'string')"></mat-icon>
                    <div class="column-name">State</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'state')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'active', 'string')"></mat-icon>
                    <div class="column-name">Active</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'active')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'source', 'string')"></mat-icon>
                    <div class="column-name">Source</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'source')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'status', 'string')"></mat-icon>
                    <div class="column-name">Status</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'status')"></div>
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody #row_tbody *ngFor="let row of showedRows; let i = index">
                <tr (click)="toggleRow(row, $event.target, row_tbody)" [ngClass]="{'highlight-previous': row['source'] !='project'}">
                    <td>{{ row['id_project'] }}</td>
                    <td>{{ row['id_content'] }}</td>
                    <td>{{ row['belongs'] }}</td>
                    <td>{{ row['class'] }}</td>
                    <td>{{ row['study'] }}</td>
                    <td>{{ row['module'] }}</td>
                    <td style="white-space: nowrap;">{{ row['year'] }}</td>
                    <td style="white-space: nowrap;">{{ row['period'] }}</td>
                    <td>{{ row['section'] }}</td>
                    <td >{{ row['type']}}</td>
                    <td [innerHTML]="stateTagsDisplay(row['state'])"></td>
                    <td [innerHTML]="stateTagsDisplay(row['active'])"></td>
                    <td >{{ row['source']}}</td>
                    <td >{{ row['status']}}</td>
                    <td class="to_skip_element select_container">
                        <div *ngIf="row['nb_duplicate'] > 1  && row['source'] == 'project'" class="duplicate-mark"></div>
                        <mat-icon *ngIf="row['source']=='project'"  sorted="" fontIcon="more_vert" style="color: gray; font-size: 20px;" (click)="contentOption(row)"></mat-icon>
                    </td>
                </tr>
                <tr class="expand_detail" *ngIf="row['expanded']">
                    <td colspan="14">
                        <app-vcl-detail-content [rowInfos]="row" [userInfos]="userInfos" [projectInfos]="projectInfos" (inProgress)="setWaiting($event)" (updateStates)="setUpdateStates($event)" (updateContent)="setUpdatedContent($event)"></app-vcl-detail-content>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="showedRows.length === 0">
                <tr ><td colspan="14" style="text-align: center;">No matching records found</td></tr>
            </tbody>
        </table>
    </div>
</div>