import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import config from 'config/default.json';
import { TableComponent } from 'src/app/back-office/components/table/table.component';
import { DialogComponent } from 'src/app/back-office/components/dialog/dialog.component';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';

@Component({
  selector: 'app-vcl-content',
  templateUrl: './vcl-content.component.html',
  styleUrls: ['./vcl-content.component.scss', '../../components/table/table.component.scss']
})
export class VcContentComponent extends TableComponent{
  constructor(
    private elRef:ElementRef,
    public cdRef: ChangeDetectorRef,
    public helper: HelperModule,
    public dialog: MatDialog,
    private apiService: ApiServiceModule,
    private sharedService: SharedService,
    public sanitizer: DomSanitizer) {
      super(sanitizer);
  }
  @Output() inProgress = new EventEmitter<any>();
  @Input() userInfos: { [x:string]: any } = new Map();
  pageId = 'vcl-content'
  modes:{ [x:string]: string|number }[] = [{id:1, value: 'Raw'}, {id: 2, value: 'Edited'},{id: 3, value: 'Classified'}, {id: 4, value: 'Edited & Classified'}, {id: 5, value: 'Archived'}];
  devices: any[] = [];

  ngOnInit(){
    
  }
  
  toggleRow(row:any, clickedElm:any, rowTab:any){
    let toExpand = true;
    for (var tdTab of rowTab.querySelectorAll('.to_skip_element')){
      if(tdTab.contains(clickedElm)){
        toExpand = false;
        break;
      }
    }
    if(toExpand){
      row['expanded'] =! row['expanded'];
    }
  }
  contentOption(row:any){
    
  }

  loadContentData(data: any ){
    this.sharedService.sendData({in_progress: true});

    data.mode = data?.mode?.length? data.mode: [1, 2, 3, 4, 5];
    data.mode = this.computeMode(data.mode);
    let filterData = data.filter_data;
    data.filter_data = [{key: 'id_country', value: filterData?.country?.id || null}, {key: 'id_cycle', value: filterData?.cycle?.id || null}, {key: 'id_region', value: filterData.region?.id || null}, {key: 'id_institution', value: filterData?.institution?.id || null}]
    
    this.apiService.queryData(config.master.ndapi.url + '/virtualclass/web/content/load', data).subscribe((response)=>{
      
      this.dataSource = response.map((row) => {
        row["expanded"] = false;
        row["checked"] = false;
        row["is_duplicate"] = row["duplicate"].split(',').length > 1? true: false;
        let duplicates = row["duplicate"].split(',').map((el:any) => parseInt(el)).filter((el:any) => el !== row["id"]).sort((a:any, b:any) => a - b).map((el:any) => {return {key: 'replace-'+el, value: 'Replace '+el}});
        row["actions"] = [{key: 'raw', value: 'Raw'},{key: 'archived', value: 'Archive'}].concat(duplicates);
        return row;
      });
      
      this.showedRows = this.dataSource;
      this.devices = Array.from(new Set(response.map(el=>el.device)));
      this.sharedService.sendData({in_progress: false});
    })
  }
  updateSelectedOption(rowInfos:any, element:any){
    console.log(element)
    let action = element.value;
    if(action != ''){
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '50%',
        position: {top: '30px' },
        data: {page: 'confirmation', text: 'This change will affect Students subscriptions. Continue ?'}
      });
      dialogRef.afterClosed().subscribe((toContinue:boolean) => {
        if(toContinue){
          const formData = new FormData();
          this.sharedService.sendData({in_progress: true});
    
          let data = {
            action: action,
            raw_infos: rowInfos,
            user_infos: this.userInfos
          }
          this.apiService.queryData(config.master.ndapi.url + '/virtualclass/web/content/update_content_state', data).subscribe((response:any)=>{
            if(response.status){
              this.setUpdateStates(response);
              this.helper.showToast("Action updated...");
            }
            this.sharedService.sendData({in_progress: false});
          })
        }
      });
    }
    
  }
  computeMode(modes:any){
    let allStates:any = {
      1: 'raw',
      2: 'edited',
      3: 'classified',
      4: 'classified,edited',
      5: 'archived'
    }
    let states:any = []
    modes.forEach((i:any) => states.push(allStates[i]));
    return states;

  }
  setWaiting(value:any){
    this.inProgress.emit(value);
  }
  setUpdateStates(data:any){
    this.dataSource = this.dataSource.map((element:any) => {
      if (element.id_content == data.id_content) {
        element.state = data.state;
        element.active = data.active;
      }
      return element;
      });

    this.showedRows = this.showedRows.map((element:any) => {
      if (element.id_content == data.id_content) {
        element.state = data.state;
        element.active = data.active;
      }
      return element;
      });
  }
  setUpdatedContent(content:any){
    this.dataSource = this.dataSource.map((element:any) => {
      if (element.id_content == content.id_content) return this.formatRowDisplay(element, content)
      return element;
    });
    this.showedRows = this.showedRows.map((element:any) => {
      if (element.id_content == content.id_content) return this.formatRowDisplay(element, content)
      return element;
    });
  }
  formatRowDisplay(element:{ [x:string]: any }, content:{ [x:string]: any }){
    element['belongs']      =   content['belongs'];
    element['id_country']   =   content['id_country'];
    element['id_cycle']     =   content['id_cycle'];
    element['id_region']    =   content['id_region'];
    element['id_institution']=   content['id_institution'];
    element['id_study']     =   content['id_study'];
    element['study']        =   content['study'];
    element['id_class']     =   content['id_class'];
    element['class']        =   content['class'];
    element['id_module']    =   content['id_module'];
    element['module']       =   content['module'];
    element['id_year']      =   content['id_year'];
    element['year']         =   content['year'];
    element['id_period']    =   content['id_period'];
    element['period']       =   content['period'];
    element['id_section']   =   content['id_section'];
    element['section']      =   content['section'];
    element['id_type']      =   content['id_type'];
    element['type']         =   content['type'];
    element['id_detail']    =   content['id_detail'];
    element['content_name'] =   content['content_name'];
    element['content_url']  =   content['content_url'];
    element['state']        =   content['state'];
    element['active']       =   content['active'];
    element['duplicate']    =   content['duplicate'];
    element['state']        =   content['state'];
    element["is_duplicate"] = content["duplicate"].split(',').length > 1? true: false;
    let duplicates = content["duplicate"].split(',').map((el:any) => parseInt(el)).filter((el:any) => el !== content["id"]).sort((a:any, b:any) => a - b).map((el:any) => {return {key: 'replace-'+el, value: 'Replace '+el}});
    element["actions"] = [{key: 'raw', value: 'Raw'},{key: 'archived', value: 'Archive'}].concat(duplicates);
    return element;
  }
  applyFilterLocal(element:any, column:string){
    let tableBody = this.elRef.nativeElement.querySelector('tbody');
    this.removeDetailsRows(tableBody);
    this.applyFilter(element.value, column);
    this.cdRef.detectChanges();
  }
  applySortLocal(element:any, column:string, type:string){
    let tableBody = this.elRef.nativeElement.querySelector('tbody');
    this.removeDetailsRows(tableBody);
    this.applySort(element, column, type);
    this.cdRef.detectChanges();
  }
}
