import { ChangeDetectorRef, Component , ElementRef, HostListener, Inject, Renderer2, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import config from 'config/default.json';

@Component({
  selector: 'app-setting-promotion',
  templateUrl: './setting-promotion.component.html',
  styleUrls: ['./setting-promotion.component.scss']
})
export class SettingPromotionComponent {
  constructor(
    private apiService: ApiServiceModule,
    private helper: HelperModule,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<SettingPromotionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { [x:string]: any }
    ) {}
  nameControl = new FormControl();
  promoCodeControl = new FormControl();
  productControl = new FormControl();
  priceRateControl = new FormControl();
  creditRateControl = new FormControl();
  typeControl = new FormControl();
  beginDateControl = new FormControl();
  expirationDateControl = new FormControl();
  activeControl = new FormControl();

  action: string = this.data['action'];
  productData: any[] = this.data['product'];
  
  rowInfos: { [x:string]: any } = this.data['row_infos'];
  userInfos: { [x:string]: any } = this.data['user_infos'];
  platformInfos: { [x:string]: any } = this.data['platform_infos'];

  ngOnInit(){

  }
  applyAction(){
    let promoName = this.nameControl.value;
    let promoCode = this.promoCodeControl.value;
    let idProduct = this.productControl.value;
    let priceRate = this.priceRateControl.value;
    let creditRate = this.creditRateControl.value;
    let typePromo = this.typeControl.value;
    let active = this.activeControl.value;

    let beginDate: any = new Date(this.beginDateControl.value).toLocaleDateString("fr-FR").replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')+ ' 00:00:00';
    let expirationDate:any = new Date(this.expirationDateControl.value).toLocaleDateString("fr-FR").replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')+ ' 00:00:00';
    beginDate = beginDate.split('-')[0] == '1970' || beginDate.includes('Invalid')? null: beginDate;
    expirationDate = expirationDate.split('-')[0] == '1970' || expirationDate.includes('Invalid')? null: expirationDate;

    if(this.action == 'create_promo'){
      if(promoName && promoCode && priceRate && creditRate && typePromo){
        let data = {
          action: this.action,
          name: promoName,
          promo_code: promoCode,
          id_product: idProduct,
          price_rate: priceRate,
          credit_rate: creditRate,
          promo_type: typePromo,
          begin_date: beginDate,
          expiration_date: expirationDate,
          active: active,
          user_infos: this.userInfos,
          platform_infos: this.platformInfos,
        }
        this.apiService.queryData(config.master.ndapi.url +'/enovsky/web/promotion/create', data).subscribe((response:any)=>{
          this.helper.showToast(response.message);
          if(response.status){
            this.dialogRef.close(response);
          }
        })
      }else this.helper.showToast('Not all fields are filled...')
      
    }else
    if(this.action == 'delete_promo'){
      let data = {
        action: this.action,
        id_promotion: this.rowInfos['id'],
        user_infos: this.userInfos,
        platform_infos: this.platformInfos,
      }
      this.apiService.queryData(config.master.ndapi.url +'/enovsky/web/promotion/delete', data).subscribe((response:any)=>{
        this.helper.showToast(response.message);
        if(response.status){
          this.dialogRef.close(response);
        }
      })
    }
    
  }
  resetDate(){
    this.beginDateControl.setValue(null);
    this.expirationDateControl.setValue(null);
  }
  close(){
    this.dialogRef.close();
  }
}
