import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule} from '@angular/material/dialog';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MainEnovskyComponent } from './back-office/enovsky/main-enovsky/main-enovsky.component';
import { LoginComponent } from './back-office/components/login/login.component';
import { RegistrationComponent } from './back-office/components/registration/registration.component';
import { DialogComponent } from './back-office/components/dialog/dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import {ApiServiceModule} from './back-office/modules/api-service.module';
import { HelperModule } from './back-office/modules/helper.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MainVirtualCampusComponent } from './back-office/virtualcampus/main-virtualcampus/main-virtualcampus.component';
import { MainVirtualclassComponent } from './back-office/virtualclass/main-virtualclass/main-virtualclass.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MainComponent } from './back-office/main/main.component';
import {MatButtonModule} from '@angular/material/button';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { SideEnovskyComponent } from './back-office/enovsky/side-enovsky/side-enovsky.component';
import { SideVirtualCampusComponent } from './back-office/virtualcampus/side-virtualcampus/side-virtualcampus.component';
import { VcaUploadContentComponent } from './back-office/virtualcampus/vca-upload-content/vca-upload-content.component';
import { VcaDepartmentComponent } from './back-office/virtualcampus/vca-department/vca-department.component';
import { VcaFilterComponent } from './back-office/virtualcampus/vca-filter/vca-filter.component';
import {MatSelectModule} from '@angular/material/select';
import { TableComponent } from './back-office/components/table/table.component';
import { VcaDetailContentComponent } from './back-office/virtualcampus/vca-content/vca-detail-content/vca-detail-content.component';
import { ProfileEnovskyComponent } from './back-office/enovsky/profile-enovsky/profile-enovsky.component';
import { PdfViewerComponent } from './back-office/components/pdf-viewer/pdf-viewer.component';
import { EditRawComponent } from './back-office/components/edit-raw/edit-raw.component';
import { VcaContentComponent } from './back-office/virtualcampus/vca-content/vca-content.component';
import { VcaCreateProjectComponent } from './back-office/virtualcampus/vca-create-project/vca-create-project.component';
import { VcaContentProjectComponent } from './back-office/virtualcampus/vca-content-project/vca-content-project.component';
import { ProjectSettingComponent } from './back-office/components/project-content/project-setting/project-setting.component';
import { ProjectEditingComponent } from './back-office/components/edit-project/project-editing/project-editing.component';
import { ProjectSplitingComponent } from './back-office/components/edit-project/project-spliting/project-spliting.component';
import { SideVirtualclassComponent } from './back-office/virtualclass/side-virtualclass/side-virtualclass.component';
import { VcContentComponent } from './back-office/virtualclass/vcl-content/vcl-content.component';
import { VcContentProjectComponent } from './back-office/virtualclass/vcl-content-project/vcl-content-project.component';
import { VcCreateProjectComponent } from './back-office/virtualclass/vcl-create-project/vcl-create-project.component';
import { VcUploadContentComponent } from './back-office/virtualclass/vcl-upload-content/vcl-upload-content.component';
import { VcFilterComponent } from './back-office/virtualclass/vcl-filter/vcl-filter.component';
import { ProjectContentComponent } from './back-office/components/project-content/project-content.component';
import { ProjectCreationComponent } from './back-office/components/project-creation/project-creation.component';
import { UploadContentComponent } from './back-office/components/upload-content/upload-content.component';
import { RawSettingComponent } from './back-office/components/project-creation/raw-setting/raw-setting.component';
import { ProjectClassifyComponent } from './back-office/components/edit-project/project-editing/project-classify/project-classify.component';
import { UserAdminComponent } from './back-office/enovsky/user-admin/user-admin.component';
import { VcDetailContentComponent } from './back-office/virtualclass/vcl-content/vcl-detail-content/vcl-detail-content.component';
import { UploadSettingComponent } from './back-office/components/edit-project/project-editing/upload-setting/upload-setting.component';
import { ServerAdminComponent } from './back-office/enovsky/server-admin/server-admin.component';
import { DocumentationComponent } from './back-office/enovsky/documentation/documentation.component';
import { VcaStudentDashboardComponent } from './back-office/enovsky/vca-student-dashboard/vca-student-dashboard.component';
import { VcStudentDashboardComponent } from './back-office/enovsky/vcl-student-dashboard/vcl-student-dashboard.component';
import { VcStudentManagerComponent } from './back-office/enovsky/vcl-student-manager/vcl-student-manager.component';
import { VcaStudentManagerComponent } from './back-office/enovsky/vca-student-manager/vca-student-manager.component';
import { ChatMainComponent } from './chat/chat-main/chat-main.component';
import { EnovskyBannerComponent } from './enovsky/enovsky-banner/enovsky-banner.component';
import { VcaAdvertismentComponent } from './back-office/virtualcampus/vca-advertisment/vca-advertisment.component';
import { VcaPromotionComponent } from './back-office/virtualcampus/vca-promotion/vca-promotion.component';
import { VclPromotionComponent } from './back-office/virtualclass/vcl-promotion/vcl-promotion.component';
import { VclAdvertismentComponent } from './back-office/virtualclass/vcl-advertisment/vcl-advertisment.component';
import { AdvertismentComponent } from './back-office/components/advertisment/advertisment.component';
import { PromotionComponent } from './back-office/components/promotion/promotion.component';
import { DetailPromotionComponent } from './back-office/components/promotion/detail-promotion/detail-promotion.component';
import { DetailAdvertismentComponent } from './back-office/components/advertisment/detail-advertisment/detail-advertisment.component';
import { SettingPromotionComponent } from './back-office/components/promotion/setting-promotion/setting-promotion.component';
import { SettingAdvertismentComponent } from './back-office/components/advertisment/setting-advertisment/setting-advertisment.component';

@NgModule({
  declarations: [
    AppComponent,
    MainEnovskyComponent,
    LoginComponent,
    RegistrationComponent,
    DialogComponent,
    MainVirtualCampusComponent,
    MainVirtualclassComponent,
    MainComponent,
    SideEnovskyComponent,
    SideVirtualCampusComponent,
    VcaUploadContentComponent,
    VcaDepartmentComponent,
    VcaFilterComponent,
    TableComponent,
    VcaDetailContentComponent,
    ProfileEnovskyComponent,
    PdfViewerComponent,
    EditRawComponent,
    VcaContentComponent,
    VcaCreateProjectComponent,
    VcaContentProjectComponent,
    ProjectSettingComponent,
    ProjectEditingComponent,
    ProjectSplitingComponent,
    SideVirtualclassComponent,
    VcContentComponent,
    VcContentProjectComponent,
    VcCreateProjectComponent,
    VcUploadContentComponent,
    VcFilterComponent,
    ProjectContentComponent,
    ProjectCreationComponent,
    UploadContentComponent,
    RawSettingComponent,
    ProjectClassifyComponent,
    UserAdminComponent,
    VcDetailContentComponent,
    UploadSettingComponent,
    ServerAdminComponent,
    DocumentationComponent,
    VcaStudentDashboardComponent,
    VcStudentDashboardComponent,
    VcStudentManagerComponent,
    VcaStudentManagerComponent,
    ChatMainComponent,
    EnovskyBannerComponent,
    VcaAdvertismentComponent,
    VcaPromotionComponent,
    VclPromotionComponent,
    VclAdvertismentComponent,
    AdvertismentComponent,
    PromotionComponent,
    DetailPromotionComponent,
    DetailAdvertismentComponent,
    SettingPromotionComponent,
    SettingAdvertismentComponent,
  ],
  imports: [
    MatButtonModule,
    BrowserModule,
    MatToolbarModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BrowserModule,
    HttpClientModule,
    FlexLayoutModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatSidenavModule,
    MatListModule,
    MatProgressBarModule,
    DragDropModule,
    RouterModule.forRoot([
      {path: 'virtualcampus', component: MainVirtualCampusComponent},
      {path: 'virtualclass', component: MainVirtualclassComponent},
      {path: '', component: MainEnovskyComponent},
    ]),
  ],
  providers: [ApiServiceModule, HelperModule, HttpClient, MatSnackBar],
  bootstrap: [AppComponent]
})
export class AppModule { }
