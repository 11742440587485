import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-vca-department',
  templateUrl: './vca-department.component.html',
  styleUrls: ['./vca-department.component.scss']
})
export class VcaDepartmentComponent {
  @Input() userInfos: { [x:string]: any } = new Map();
}
