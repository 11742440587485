import { ChangeDetectorRef, Component , ElementRef, HostListener, Inject, Renderer2, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import config from 'config/default.json';

@Component({
  selector: 'app-setting-advertisment',
  templateUrl: './setting-advertisment.component.html',
  styleUrls: ['./setting-advertisment.component.scss']
})
export class SettingAdvertismentComponent {
  constructor(
    private apiService: ApiServiceModule,
    private helper: HelperModule,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<SettingAdvertismentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { [x:string]: any }
    ) {}
  nameControl = new FormControl();
  fullNameControl = new FormControl();
  typeControl = new FormControl();
  descriptionControl = new FormControl();
  formDataAndFiles:any  = new FormData();
  activeControl = new FormControl();

  action: string = this.data['action'];
  
  rowInfos: { [x:string]: any } = this.data['row_infos'];
  userInfos: { [x:string]: any } = this.data['user_infos'];
  platformInfos: { [x:string]: any } = this.data['platform_infos'];

  ngOnInit(){

  }
  applyAction(){
    let name = this.nameControl.value;
    let fullName = this.fullNameControl.value;
    let type = this.typeControl.value;
    let description = this.descriptionControl.value;
    let active = this.activeControl.value;

    if(this.action == 'create_advertisment'){
      if(name && type){
        let data = {
          name: name,
          full_name: fullName,
          type: type,
          active: active,
          description: description,
          user_infos: this.userInfos,
          platform_infos: this.platformInfos,
        }
        if(this.formDataAndFiles.getAll('file').length){
          this.formDataAndFiles.delete('data');
          this.formDataAndFiles.append('data', JSON.stringify(data));
          this.apiService.uploadDataAndFile(config.master.ndapi.url +'/enovsky/web/advertisment/create', this.formDataAndFiles).subscribe((response:any)=>{
            this.helper.showToast(response.message);
            if(response.status){
              this.dialogRef.close(response);
            }
          })
        }else this.helper.showToast("No file selected..."); 
        
      }else this.helper.showToast('Not all fields are filled...')
      
    }else
    if(this.action == 'delete_advertisment'){
      let data = {
        action: this.action,
        id_advertisment: this.rowInfos['id'],
        file_name: this.rowInfos['file_name'],
        user_infos: this.userInfos,
        platform_infos: this.platformInfos,
      }
      this.apiService.queryData(config.master.ndapi.url +'/enovsky/web/advertisment/delete', data).subscribe((response:any)=>{
        this.helper.showToast(response.message);
        if(response.status){
          this.dialogRef.close(response);
        }
      })
    }
    
  }
  onFileSelected(event:any){
    let files = event.target.files;
    for(var i = 0; i < files.length; i++) this.formDataAndFiles.append("file", files[i]);
  }
  close(){
    this.dialogRef.close();
  }
}
