import { ChangeDetectorRef, Component , ElementRef, HostListener, Inject, Renderer2, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import config from 'config/default.json';

@Component({
  selector: 'app-raw-setting',
  templateUrl: './raw-setting.component.html',
  styleUrls: ['./raw-setting.component.scss']
})
export class RawSettingComponent {
  constructor(
    private apiService: ApiServiceModule,
    private helper: HelperModule,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<RawSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { [x:string]: any }
    ) {}
  userControl = new FormControl();
  actionControl = new FormControl();
  userInputControl = new FormControl();
  actionValueControl = new FormControl();
  rowInfos: { [x:string]: any } = this.data['row_infos'];
  userInfos: { [x:string]: any } = this.data['user_infos'];
  selectedRows: { [x:string]: any } = this.data['selected_rows'];
  platformInfos: { [x:string]: any } = this.data['platform_infos'];
  actionMode = 'input';

  ngOnInit(){
    console.log(this.userInfos)
    this.actionControl.setValue('create_project');
  }
  applyAction(){
    let action = this.actionControl.value;
    let projectName = this.actionValueControl.value;
    if(action == 'create_project'){
      const firstSelect = this.selectedRows[0];
      let checkSameGroup = this.platformInfos['origin'] == 'virtualcampus'? (this.selectedRows as any[]).every(el => el.id_structure === firstSelect.id_structure):
                          (this.selectedRows as any[]).every(el => el.id_country === firstSelect.id_country && el.id_cycle === firstSelect.id_cycle && el.id_region === firstSelect.id_region && el.id_vcl_institution === firstSelect.id_vcl_institution);
      if(checkSameGroup){
        let data = {
          action: action,
          row_infos: this.rowInfos,
          user_infos: this.userInfos,
          project_name: projectName,
          platform_infos: this.platformInfos,
          selected_rows: this.selectedRows,
        }
        this.apiService.queryData(config.master.ndapi.url +'/enovsky/web/project/create', data).subscribe((response:any)=>{
          this.helper.showToast(response.message);
          if(response.status){
            this.dialogRef.close(response);
          }
        })
      }else this.helper.showToast('All the selections are not in the same Structure or Country - Cycle - Region - Institution')
      
    }else{
      if(action){
        let data = {
          action: action,
          row_infos: this.rowInfos,
          user_infos: this.userInfos,
          project_name: projectName,
          platform_infos: this.platformInfos,
          selected_rows: this.selectedRows,
        }
        this.apiService.queryData(config.master.ndapi.url +'/enovsky/web/raw-content/action', data).subscribe((response:any)=>{
          this.helper.showToast(response.message);
          if(response.status){
            this.dialogRef.close(response);
          }
        })
      }else this.helper.showToast('No action is specified...')
    }
    if(this.userInfos['privilege'] >= 3 || ['create_project'].includes(action)){
      
    }else this.helper.showToast('No enough privileges for this action...')
    
    
  }
  switchAction(){
    let action = this.actionControl.value;
    this.actionMode = action == 'create_project'? 'input':'';
  }
  close(){
    this.dialogRef.close();
  }
}
