import { Component, Output, EventEmitter, ElementRef } from '@angular/core';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import config from 'config/default.json';
import { FormControl } from '@angular/forms';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {
  constructor(
    private elRef:ElementRef,
    private apiService: ApiServiceModule,
    private helper: HelperModule,
    private formBuilder: FormBuilder
    ) {}
  formGroup!: FormGroup;
  @Output() connectAccountEvent = new EventEmitter<any>();
  ngOnInit(){
    this.formGroup = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      mail: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      phone: ['', [Validators.required, Validators.pattern('[0-9]+')]],
      password: ['', [Validators.required, Validators.pattern(/.{6,}/)]],
      confirm_pass: ['', [Validators.required, Validators.pattern(/.{6,}/)]],
    });
  }
  registerUser(){
    if (this.formGroup.valid) {
      const data = this.formGroup.value;
      this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/administration/user_register', data).subscribe((response:any)=>{
        this.helper.showToast(response.message);
      }) 
    }else this.helper.showToast("Vous avez des erreurs dans le formulaire..");
  }
  connectUser(){
    this.connectAccountEvent.emit();
  }
}
