import { ChangeDetectorRef, Component , ElementRef, HostListener, Inject, Renderer2, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import config from 'config/default.json';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
@Component({
  selector: 'app-project-setting',
  templateUrl: './project-setting.component.html',
  styleUrls: ['./project-setting.component.scss']
})
export class ProjectSettingComponent {
  constructor(
    private apiService: ApiServiceModule,
    private helper: HelperModule,
    private cdRef: ChangeDetectorRef,
    private sharedService: SharedService,
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<ProjectSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { [x:string]: any }
    ) {}
  userControl = new FormControl();
  waveControl = new FormControl();
  actionControl = new FormControl();
  userInputControl = new FormControl();
  actionValueControl = new FormControl();
  rowInfos: { [x:string]: any } = this.data['row_infos'];
  userInfos: { [x:string]: any } = this.data['user_infos'];
  platformInfos: { [x:string]: any } = this.data['platform_infos'];
  actionMode = 'input';

  actions: { [x:string]: any } = {
    //Created - Splitting - Splitted - Editing
    'created': [{key: 'rename_project', level: 2, value: 'Rename Project'}, {key: 'assign_splitter', level: 3, value: 'Assign Splitter - Classifier'}],
    'splitting': [{key: 'rename_project', level: 2, value: 'Rename Project'}, {key: 'assign_splitter', level: 3, value: 'Assign to an other Splitter - Classifier'}, {key: 'remove_splitter', level: 3, value: 'Remove Splitter - Classifier'}, {key: 'assign_editor', level: 3, value: 'Assign Editor' }],
    'splitted': [{key: 'rename_project', level: 2, value: 'Rename Project'}, {key: 'assign_splitter', level: 3, value: 'Assign to an other Splitter - Classifier'}, {key: 'assign_editor', level: 3, value: 'Assign Editor' }, {key: 'remove_splitter', level: 3, value: 'Remove Splitter - Classifier'}],
    'editing': [{key: 'rename_project', level: 2, value: 'Rename Project'}, {key: 'assign_editor', level: 3, value: 'Assign to an other Editor'}, {key: 'assign_splitter', level: 3, value: 'Rollback to Splitter'}, {key: 'remove_editor', level: 3, value: 'Remove Editor'}, {key: 'count_word', level: 2, value: 'Count Project\'s Words'}]
  }
  responseData: { [x:string]: any } = {data: null};
  projectActions!:{ [x:string]: any }[];
  allUsers:any = [];
  userWaves:any = [];
  searchInProgress:boolean = false;

  ngOnInit(){
    this.projectActions = this.actions[this.rowInfos['state']];
    this.actionControl.setValue('rename_project');
    this.actionValueControl.setValue(this.rowInfos['project_name']);
    this.userInputControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((query) => {
        this.searchInProgress = true;
        return this.apiService.queryData(config.master.ndapi.url+'/enovsky/web/administration/search_user', {search_key: query})
      })
    ).subscribe((options) => {
      this.allUsers = options;
      this.searchInProgress = false;
    });
  }
  updateWave(){
    let action = this.actionControl.value;
    let idUser = this.userControl.value;
    if(['assign_splitter','assign_editor'].includes(action) && idUser){
      let data = {
        action: action,
        id_user: idUser,
        platform_infos: this.platformInfos
      }
      this.sharedService.sendData({in_progress: true});
      this.apiService.queryData(config.master.ndapi.url +'/enovsky/web/project/get_user_waves', data).subscribe((response:any)=>{
        this.userWaves = response;
      })
    }else this.helper.showToast('No action is specified...');
  }
  applyAction(){
    let action = this.actionControl.value;
    let idUser = this.userControl.value;
    let projectName = this.actionValueControl.value;
    if(action == 'count_word'){
      let contentsInfos = {
        platform_infos: this.platformInfos,
        user_infos: this.userInfos,
        id: this.rowInfos['id']
      };
      const formData = new FormData();
      formData.append('data', JSON.stringify(contentsInfos));
      this.sharedService.sendData({in_progress: true});
      this.apiService.queryData(config.master.flapi.url + '/enovsky/count_word', formData).subscribe((response:any)=>{
        this.sharedService.sendData({in_progress: false});
        this.responseData = response;
        this.dialogRef.close(response);
      });
    }else
    if(this.userInfos['privilege'] >= 3 || ['rename_project'].includes(action)){
      if(action){
        let data = {
          action: action,
          id_wave: this.waveControl.value,
          project_infos: this.rowInfos,
          user_infos: this.userInfos,
          target_id_user: idUser,
          project_name: projectName,
          platform_infos: this.platformInfos
        }
        this.sharedService.sendData({in_progress: true});
        this.apiService.queryData(config.master.ndapi.url +'/enovsky/web/project/action', data).subscribe((response:any)=>{
          this.helper.showToast(response.message);
          this.sharedService.sendData({in_progress: false});
          if(response.status){
            this.responseData = response;
            this.dialogRef.close(response);
          }
        })
      }else this.helper.showToast('No action is specified...');
    }else this.helper.showToast('No enough privileges for this action...');
  }
  switchAction(){
    let action = this.actionControl.value;
    if(action == 'rename_project') this.actionMode = 'input';
    if(['assign_splitter','assign_editor','rollback_to_splitter'].includes(action)) this.actionMode = 'select';
    if(['remove_splitter','remove_editor', 'count_word'].includes(action)) this.actionMode = '';
  }
  close(){
    this.dialogRef.close(this.responseData);
  }
}
