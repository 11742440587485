<div class="modal-dialog modal-xl modal-content">
    <div class="modal-header">
        <div><h2 class="modal-title">Raw Contents Setting </h2></div>
    </div>
    <div class="modal-body">
        <div fxLayout="row" fxLayout.xs="column">
            <div class="label-style" fxFlex="10">Action</div>
            <div class="separator"></div>
            <div class="class-mat-field" fxFlex="30">
                <mat-select placeholder="Action" [formControl]="actionControl" (selectionChange)="switchAction()">
                    <mat-option [value]="'create_project'" >Create Project</mat-option>
                    <mat-option [value]="'delete_content'" >Delete Contents</mat-option>
                </mat-select>
            </div>
            <div class="separator"></div>
            <div class="class-mat-field" fxFlex="60">
                <input *ngIf="actionMode == 'input'" type="text" class="col-input" style="margin: 1px;" placeholder="Type name" [formControl]="actionValueControl">
            </div>
        </div>
    </div>
    <hr>
    <div class="modal-footer" style="margin-top: 5px;">
        <button type="button" class="button-secondary-small left-side" (click)="close()">Close</button>
        <button type="button" class="button-validate-small" (click)="applyAction()">Validate</button>
    </div>
</div>