<div fxLayout="row" fxLayout.xs="column">
    <div fxFlex="33.33%" style="padding: 0 10px 0 10px;">
        <div fxLayout="row" class="element-style">
            <div class="label-style" fxFlex="30%">Name</div>
            <div fxFlex="70%">
                <input class="col-input" [formControl]="nameControl" placeholder="Name" style="margin: 0;">
            </div>
        </div>
        <div fxLayout="row" class="element-style">
            <div class="label-style" fxFlex="30%">Full Name</div>
            <div fxFlex="70%">
                <input class="col-input" [formControl]="fullNameControl" placeholder="Full Name" style="margin: 0;">
            </div>
        </div>
        <div fxLayout="row" class="element-style">
            <div class="label-style" fxFlex="30%">Type</div>
            <div fxFlex="70%">
                <mat-select placeholder="Type" [formControl]="typeControl">
                    <mat-option [value]="'virtualcampus'">Virtual Campus</mat-option>
                    <mat-option [value]="'enovsky'">Enovsky</mat-option>
                    <mat-option [value]="'partner'">Partenaire</mat-option>
                </mat-select>
            </div>
        </div>
    </div>
    <div fxFlex="33.33%" style="padding: 0 10px 0 10px;">
        <div fxLayout="row" class="element-style">
            <div class="label-style" fxFlex="30%">Description</div>
            <div fxFlex="70%">
                <input class="col-input" [formControl]="descriptionControl" placeholder="Description" style="margin: 0;">
            </div>
        </div>
        <div fxLayout="row" class="element-style">
            <div class="label-style" fxFlex="30%">Active</div>
            <div fxFlex="70%">
                <mat-select placeholder="Active" [formControl]="activeControl">
                    <mat-option [value]="false">False</mat-option>
                    <mat-option [value]="true">True</mat-option>
                </mat-select>
            </div>
        </div>
        <div fxLayout="row" class="element-style">
            <div class="label-style" fxFlex="30%">Image</div>
            <div fxFlex="70%">
                <input class="col-input file_erea" type="file" accept=".svg, .png, .jpg, .jpeg, .webp" (change)="onFileSelected($event)">
            </div>
            
        </div>
    </div>
    <div fxFlex="33.33%" style="padding: 0 10px 0 10px; display: flex; justify-content: center;">
        <img [src]="ndApiUrl+'/'+platformInfos['root']+'/'+rowInfos['file_name']" height="200" style="max-width: 100%; max-height: 100%;">
    </div>
</div>
<div style="padding-top: 15px;"></div>
<div fxLayoutAlign="center center">
    <button class="button-validate-small" (click) = "applyUpdate()">Validate</button>
</div>