import { ChangeDetectorRef, Component , ElementRef, HostListener, Inject, Renderer2, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import config from 'config/default.json';

@Component({
  selector: 'app-upload-setting',
  templateUrl: './upload-setting.component.html',
  styleUrls: ['./upload-setting.component.scss']
})
export class UploadSettingComponent {
  constructor(
    private apiService: ApiServiceModule,
    private helper: HelperModule,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<UploadSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { [x:string]: any }
    ) {}
  contentControl = new FormControl();
  actionControl = new FormControl();
  contentInputControl = new FormControl();
  actionValueControl = new FormControl();
  rowInfos: { [x:string]: any } = this.data['row_infos'];
  duplicatesRows: { [x:string]: any }[] = this.data['duplicate_rows'];
  
  userInfos: { [x:string]: any } = this.data['user_infos'];
  platformInfos: { [x:string]: any } = this.data['platform_infos'];
  projectInfos: { [x:string]: any } = this.data['project_infos'];
  actionMode = 'input';

  contentTitle = '';
  responseData: { [x:string]: any } = {data: null};
  contentActions!:{ [x:string]: any }[];
  searchInProgress:boolean = false;

  ngOnInit(){
    this.contentTitle = this.platformInfos['origin'] == 'virtualcampus'? this.rowInfos['id_content']+' - '+this.rowInfos['department']+' - '+this.rowInfos['semester']+' - '+this.rowInfos['module']+' - '+this.rowInfos['year']+' - '+this.rowInfos['section']+' - '+this.rowInfos['type']:
                                                                        this.rowInfos['id_content']+' - '+this.rowInfos['belongs']+' - '+this.rowInfos['class']+' - '+this.rowInfos['study']+' - '+this.rowInfos['module']+' - '+this.rowInfos['year']+' - '+this.rowInfos['period']+' - '+this.rowInfos['section']+' - '+this.rowInfos['type']
    this.actionControl.setValue('import');
    this.actionValueControl.setValue('import');
  
  }
  applyAction(){
    let action = this.actionControl.value;
    let idContent = this.contentControl.value;
    let projectName = this.actionValueControl.value;
    if(this.userInfos['privilege'] >= 3){
      if(action){
        let data = {
          action: action,
          row_infos: this.rowInfos,
          user_infos: this.userInfos,
          target_id_content: idContent,
          project_name: projectName,
          platform_infos: this.platformInfos,
          project_infos: this.projectInfos
        }
        this.apiService.queryData(config.master.ndapi.url +'/enovsky/web/content/upload_content', data).subscribe((response:any)=>{
          this.helper.showToast(response.message);
          if(response.status){
            this.responseData = response;
            this.dialogRef.close(response);
          }
        })
      }else this.helper.showToast('No action is specified...')
    }else this.helper.showToast('No enough privileges for this action...')
    
    
  }
  switchAction(){
    let action = this.actionControl.value;
    if(['replace'].includes(action)) this.actionMode = 'select';
    if(['archive','new','import'].includes(action)) this.actionMode = '';
  }
  close(){
    this.dialogRef.close(this.responseData);
  }
}
