<div fxLayout="row" style="padding: 20px 10px 20px 10px;">
    <div class="center-vertical" fxFlex="20%">
        <p>Total: {{this.showedRows.length}}</p>
    </div>
    <div class="center-vertical" fxFlex="40%" fxLayoutAlign="center center">
        <button type="button" class="button-secondary-small" (click)="settingPromotion('create_promo', {})"  style="white-space: nowrap; align-items: center; display: flex; margin-right: 3px;">
            <mat-icon fontIcon="settings" style="color: gray; cursor: pointer; margin-right: 5px; font-size: 20px; width: fit-content; height: fit-content;"></mat-icon>
            <div>Create New Promotion</div>
        </button>
    </div>
    <div class="class-mat-field" fxFlex="25%"  fxLayoutAlign="center center">
        <button class="button-validate-small" (click) = "loadPromotionData()">Refresh</button>
    </div>

    <div class="center-vertical" fxFlex="40%" style="display: flex; align-items: center;">
        <div style="margin-left: auto;">
            <input class="col-input"  (keyup)="applyFilterLocal($event.target, 'all_column')" placeholder="Search">
        </div>
    </div>
</div>
<div class="table_div">
    <table>
        <thead>
        <tr>
            <th>
                <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'id', 'number')"></mat-icon>
                <div class="column-name">Id</div>
                <input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'id')">
            </th>
            <th>
                <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'promo_name', 'string')"></mat-icon>
                <div class="column-name">Name</div>
                <input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'promo_name')">
            </th>
            <th>
                <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'promo_type', 'string')"></mat-icon>
                <div class="column-name">Type</div>
                <mat-select placeholder="Choose" style="height: 22px;" (selectionChange)="applyFilterLocal($event, 'promo_type')">
                    <mat-option [value]="''">Choose</mat-option>
                    <mat-option [value]="result" *ngFor="let result of promoTypes; let i = index">{{result}}</mat-option>
                </mat-select>
            </th>
            <th>
                <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'price', 'string')"></mat-icon>
                <div class="column-name">Price</div>
                <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'price')"></div>
            </th>
            <th>
                <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'credit', 'number')"></mat-icon>
                <div class="column-name">Credit</div>
                <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'credit')"></div>
            </th>
            <th>
                <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'begin_date', 'date')"></mat-icon>
                <div class="column-name">Begin date</div>
                <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'begin_date')"></div>
            </th>
            <th>
                <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'expiration_date', 'string')"></mat-icon>
                <div class="column-name">Expiration date</div>
                <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'expiration_date')"></div>
            </th>
            <th>
                <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'nb_student', 'number')"></mat-icon>
                <div class="column-name">Nb Student</div>
                <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'nb_student')"></div>
            </th>
            <th>
                <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'product_name', 'string')"></mat-icon>
                <div class="column-name">Product</div>
                <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'product_name')"></div>
            </th>
            <th>
                <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'active', 'string')"></mat-icon>
                <div class="column-name">Active</div>
                <mat-select placeholder="Choose" style="height: 22px;" (selectionChange)="applyFilterLocal($event, 'active')">
                    <mat-option [value]="''">Choose</mat-option>
                    <mat-option [value]="false">False</mat-option>
                    <mat-option [value]="true">True</mat-option>
                </mat-select>
            </th>
            <th></th>
        </tr>
        </thead>
        <tbody #row_tbody *ngFor="let row of showedRows; let i = index">
            <tr (click)="toggleRow(row, $event.target, row_tbody)" [ngClass]="{'highlight-previous': row['source']=='content'}">
                <td>{{ row['id'] }}</td>
                <td>{{ row['promo_name'] }}</td>
                <td style="font-weight: bold;">{{ row['promo_type'] }}</td>
                <td style="white-space: nowrap;">{{ row['price'] }} FCFA</td>
                <td style="white-space: nowrap;">{{ row['credit'] }}</td>
                <td >{{ row['begin_date']}}</td>
                <td >{{ row['expiration_date']}}</td>
                <td >{{ row['nb_student']}}</td>
                <td [innerHTML]="stateTagsDisplay(row['product_name'])"></td>
                <td [innerHTML]="stateTagsDisplay(row['active'])"></td>
                <td class="to_skip_element">
                    <div style="display: flex;">
                        <!-- <mat-icon  sorted="" fontIcon="open_in_new" style="color: gray; font-size: 20px;"></mat-icon> -->
                        <mat-icon *ngIf="!['classic','welcome','shared_content','sponsored','sponsor'].includes(row['promo_type'])"  sorted="" fontIcon="delete" style="color: gray; font-size: 20px;" (click)="settingPromotion('delete_promo', row)"></mat-icon>
                    </div>
                </td>
            </tr>
            <tr class="expand_detail" *ngIf="row['expanded']">
                <td colspan="11">
                    <app-detail-promotion [rowInfos]="row" [userInfos]="userInfos" [productData]="productData" [platformInfos]="platformInfos" (inProgress)="setWaiting($event)" (updatePromotion)="setUpdatePromotion($event)"></app-detail-promotion>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="showedRows.length === 0">
            <tr ><td colspan="11" style="text-align: center;">No matching records found</td></tr>
        </tbody>
    </table>
</div>
