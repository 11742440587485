import { ChangeDetectorRef, Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
import config from 'config/default.json';

@Component({
  selector: 'app-main-virtualclass',
  templateUrl: './main-virtualclass.component.html',
  styleUrls: ['./main-virtualclass.component.scss']
})
export class MainVirtualclassComponent {
  constructor(
    private elRef:ElementRef,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private sharedService: SharedService,
    private apiService: ApiServiceModule,
    ) {}
  pageId = 'vcl-content-project';;
  
  userInfos: { [x:string]: any } = new Map();
  ngAfterViewInit(){
    this.sharedService.data.subscribe(data => {
      if(data['side_class_page_id']){
        this.pageId = data['side_class_page_id'];
        
        let param = {key: 'vcl_last_page', value: this.pageId, id_user: this.userInfos['id_user']};
        this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/administration/save_settings', param).subscribe((response)=>{});
      }
      if(data['user_infos']){
        this.userInfos = data['user_infos'];
        this.pageId = this.userInfos?.['navigation_infos']?.vcl_last_page || this.pageId;
      }
      this.cdRef.detectChanges();
    });
  }
}
