import { NgModule, ChangeDetectorRef, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Component, Inject, Renderer2, ElementRef} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import config from 'config/default.json';
import { io } from "socket.io-client";
import { lastValueFrom } from 'rxjs';

declare const DocsAPI: any;

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class EditorModule{
  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    private cdRef: ChangeDetectorRef,
    private helper: HelperModule,
    private apiService: ApiServiceModule,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
  ) {}

    that: any;
    socketCloud:any;
    socketNdapi:any;
    config = config;

    pdfToShow = 'raw';
    numContent = 1;

    classifyModule:any;
    onlyofficeConf: any;
    editor: any;

    pdfContainer:any;
    pdfContainerRaw:any;
    pdfContainerCleaned:any;
    pdfContainerEdited:any;
    orientation:string = 'vertical';

    selectedContent!:{ [x:string]: any};
    triggerRebuildEditPdf:boolean = false;

    pdfDataRaw!:{ [x:string]: string|number|boolean };
    pdfDataClean!:{ [x:string]: string|number|boolean };
    pdfDataEdit!:{ [x:string]: string|number|boolean };

    

    init(that:any, classifyModule:any){
      this.that = that;
      this.classifyModule = classifyModule
      this.socketCloud = io(config.service.cloud.url);
      this.socketNdapi = io(config.master.ndapi.url);
      
    }
    showEdited(){
      if(this.that.contentData.length && this.that.contentData[0].edit_file_docx){
        this.that.cleanedActive = false;
        this.that.classifiedActive = false;
        this.that.editedActive = true;
        let slected = this.that.contentData.find((el:any) => el.id_content == this.that.contentData[0].id_content_active);

        this.selectedContent = slected? slected: this.that.contentData[0];
        this.numContent = this.selectedContent['id_content'];
        
        this.that.activatePage('task-editing');
        this.that.projectInfos['last_task'] = 'task-editing';
        this.orientation = this.elRef.nativeElement.getBoundingClientRect().width < 500? 'horizontal': this.orientation;

        this.pdfContainer = this.elRef.nativeElement.querySelector('#pdf_container');
        this.pdfContainerRaw = this.pdfContainer.querySelector('#pdf_container_raw');
        this.pdfContainerCleaned = this.pdfContainer.querySelector('#pdf_container_cleaned');
        this.pdfContainerEdited = this.pdfContainer.querySelector('#pdf_container_edited');

        this.switchPDFMode(this.pdfToShow);
        this.cdRef.detectChanges();
        this.prepareEditorSpace();
        this.loadEditorConfig();
        this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/project/save_current_task', {id_project: this.that.projectInfos['id'], cuurent_task: 'task-editing', platform_infos: this.that.platformInfos}).subscribe();
      }else this.helper.showToast('Edited content not found...');
    }
    setOrientation(orientation:string){
      let param = {key: 'edition_orientation', value: orientation, id_user: this.that.userInfos['root_id_user']};
      this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/administration/save_settings', param).subscribe((response)=>{});
      this.orientation = orientation;
    }
    switchPDFMode(page: string){
      if(page == 'raw'){
        this.pdfContainerCleaned.hidden = true;
        this.pdfContainerEdited.hidden = true;
        this.pdfContainerRaw.hidden = false;
        this.pdfToShow = 'raw';
      }
      if(page == 'cleaned'){
        this.pdfContainerRaw.hidden = true;
        this.pdfContainerEdited.hidden = true;
        this.pdfContainerCleaned.hidden = false;
        this.pdfToShow = 'cleaned';
      }
      if(page == 'edited'){
        this.pdfContainerRaw.hidden = true;
        this.pdfContainerCleaned.hidden = true;
        this.pdfContainerEdited.hidden = false;
        this.pdfToShow = 'edited';
      }
    }
   
    
    
    async loadEditorConfig(){
      let user = this.that.userInfos;
      if(this.that.source == 'content'){
        let data = {
          table: 'generate_files_urls',
          files_path: {raw_file_path: this.that.platformInfos['root']+'/'+this.selectedContent['file_path']+'_raw.pdf', edit_file_pdf: this.that.platformInfos['root']+'/'+this.selectedContent['file_path']+'.pdf', edit_file_docx: this.that.platformInfos['root']+'/'+this.selectedContent['file_path']+'.docx'},
        }
        //Get file
        const formData = new FormData();
        formData.append('data', JSON.stringify(data));
        let response:any = await lastValueFrom(this.apiService.queryData(config.service.cloud.url + '/generate_files_urls', formData));
  
        this.selectedContent['raw_file_path'] = response.raw_file_path.file_url;
        this.selectedContent['edit_file_pdf'] = response.edit_file_pdf.file_url
        this.selectedContent['edit_file_docx'] = response.edit_file_docx.file_url
      }
      let data = {
        target: this.that.source == 'content'? 'cloud': 'ndapi',
        id_content: null,
        id_project: this.that.projectInfos?.['id'] || null,
        file_url: '',
        origin: this.that.platformInfos['origin'],
        user_infos: this.that.userInfos
      }; 
      for (const [i, content] of this.that.contentData.entries()) { 
        data.id_content = content['id_content'];
        data.file_url = this.that.source == 'content'? content['edit_file_docx']: config.master.ndapi.url + '/' + this.that.platformInfos['root']+ '/' +content['edit_file_docx'];
        this.that.contentData[i]['onlyoffice_conf'] = await lastValueFrom(this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/content/onlyoffice_conf', data));
      }
      this.displayEditor(this.selectedContent, this.numContent, false);
      
    }
    displayEditor(content:any, numContent:number, saveSelected:boolean = true){
      this.numContent = numContent;
      this.selectedContent = content;
      this.socketCloud.on('onlyoffice_save', (response:any)=>{
        this.helper.showToast(response);
      })
      this.socketNdapi.on('onlyoffice_save', (response:any)=>{
        this.helper.showToast(response.message);
      });
      if(this.that.source == 'content'){
        this.pdfDataRaw = {url: this.selectedContent['raw_file_path'], refresh: true};
        this.pdfDataEdit = {url: this.selectedContent['edit_file_pdf'], refresh: true};
      }else{
        this.pdfDataRaw = {url: this.config.master.ndapi.url + '/' + this.that.platformInfos['root']+'/'+this.selectedContent['raw_file_path'], refresh: true};
        if(this.selectedContent['clean_file_path']) this.pdfDataClean = {url: this.config.master.ndapi.url + '/' + this.that.platformInfos['root']+'/'+this.selectedContent['clean_file_path'], zoom: 0.25, refresh: true};
        this.pdfDataEdit = {url: this.config.master.ndapi.url + '/' + this.that.platformInfos['root']+'/'+this.selectedContent['edit_file_pdf'], refresh: true};
      }
      
      
      
      if (this.editor) {
        this.editor.destroyEditor();
      }
      let config = content['onlyoffice_conf'];
      this.editor = new DocsAPI.DocEditor('editor-container', config);
      
      if(saveSelected){
        let data = {
          id_project: content.id_project,
          id_content: content.id_content,
          platform_infos: this.that.platformInfos,
        }
        this.apiService.queryData(this.config.master.ndapi.url + '/enovsky/web/content/save_selected_content', data).subscribe();
      }
      
    }
    resetEditedPDF(){
      if(this.that.source == 'content'){
        let data = {
          table: 'generate_files_urls',
          files_path: {edit_file_pdf: this.that.platformInfos['root']+'/'+this.selectedContent['file_path']+'.pdf'}
        }; 
        const formData = new FormData();
        formData.append('data', JSON.stringify(data));
        this.apiService.queryData(config.service.cloud.url + '/generate_files_urls', formData).subscribe(async (response:any)=>{
          this.selectedContent['edit_file_pdf'] = response.edit_file_pdf.file_url
          this.triggerRebuildEditPdf = !this.triggerRebuildEditPdf;
          console.log(this.selectedContent['edit_file_pdf'])
          this.pdfDataEdit = {url: this.selectedContent['edit_file_pdf'], refresh: true, rebuild: this.triggerRebuildEditPdf};
        })
        
      }else{
        this.triggerRebuildEditPdf = !this.triggerRebuildEditPdf;
        this.pdfDataEdit = {url: this.config.master.ndapi.url + '/' + this.that.platformInfos['root']+'/'+this.selectedContent['edit_file_pdf'], rebuild: this.triggerRebuildEditPdf, refresh: true};
      }
      
    }
    updateState(idContent:any, isChecked:boolean){
      let data = {
        source: 'project',
        state: 'edited',
        state_checked: isChecked,
        row_infos: this.selectedContent,
        user_infos: this.that.userInfos
      }
      this.apiService.queryData(config.master.ndapi.url + '/virtualcampus/web/content/update_content_state', data).subscribe((response:any)=>{
        if(response.status){
          this.that.contentData.find((element:any) => element.id_content === idContent)!.state = response.state;
          this.that.contentData.find((element:any) => element.id_content === idContent)!.active = response.active;
        }
        this.helper.showToast(response.message);
        this.that.inProgress = false;
      });
      
    }
  
    prepareEditorSpace(){
      let nativeElement = this.elRef.nativeElement;
      const leftVertical = nativeElement.querySelector('#left-vertical');
      const rightVertical = nativeElement.querySelector('#right-vertical');
      const dragVertical = nativeElement.querySelector('#drag-vertical');
      const dragHorizontalSecond = nativeElement.querySelector('#drag-horizontal-second');
      const dragVerticalSecond = nativeElement.querySelector('#drag-vertical-second');

      const zoneASecond = nativeElement.querySelector('#zone-a-second');
      const zoneBSecond = nativeElement.querySelector('#zone-b-second');
      
  
      let startTopHeight:any;
      let startBottomHeight:any;
      let startLeftWidth:any;
      let startRightWidth:any;

      const onMouseMoveVertical = (e:any) => {
        const deltaX = e.clientX - startLeftWidth;
        const newLeftWidth = startLeftWidth + deltaX;
        const newRightWidth = startRightWidth - deltaX;
  
        if (newLeftWidth >= 0 && newRightWidth >= 0) {
          leftVertical.style.width = newLeftWidth + 'px';
          rightVertical.style.width = newRightWidth + 'px';
        }
      };
      const onMouseMoveVerticalSecond = (e:any) => {
        const deltaX = e.clientX - startLeftWidth - rightVertical.getBoundingClientRect().left;
        const newLeftWidth = startLeftWidth + deltaX;
        const newRightWidth = startRightWidth - deltaX;
  
        if (newLeftWidth >= 0 && newRightWidth >= 0) {
          zoneASecond.style.width = newLeftWidth + 'px';
          zoneBSecond.style.width = newRightWidth + 'px';
        }
      };
      const onMouseMoveVerticalSecond2 = (e:any) => {
        const deltaX = e.clientX - startLeftWidth - rightVertical.getBoundingClientRect().left;
        const newLeftWidth = startLeftWidth + deltaX;
        const newRightWidth = startRightWidth - deltaX;
  
        if (newLeftWidth >= 0 && newRightWidth >= 0) {
          zoneBSecond.style.width = newLeftWidth + 'px';
          zoneASecond.style.width = newRightWidth + 'px';
        }
      };
      const onMouseMoveHorizontalSecond = (e:any) => {
        const deltaY = e.clientY - startTopHeight - 40;
        const newTopHeight = startTopHeight + deltaY;
        const newBottomHeight = startBottomHeight - deltaY;
  
        if (newTopHeight >= 0 && newBottomHeight >= 0) {
          zoneASecond.style.height = newTopHeight + 'px';
          zoneBSecond.style.height = newBottomHeight + 'px';
        }
      };
  
      const onMouseUp = () => {
        nativeElement.removeEventListener('mousemove', onMouseMoveHorizontalSecond);
        nativeElement.removeEventListener('mousemove', onMouseMoveVertical);
        nativeElement.removeEventListener('mousemove', onMouseMoveVerticalSecond);
        nativeElement.removeEventListener('mousemove', onMouseMoveVerticalSecond2);
        nativeElement.removeEventListener('mouseup', onMouseUp);
      };
      dragVertical.addEventListener('mousedown', (e:any) => {
        startLeftWidth = leftVertical.getBoundingClientRect().width;
        startRightWidth = rightVertical.getBoundingClientRect().width;
        nativeElement.addEventListener('mousemove', onMouseMoveVertical);
        nativeElement.addEventListener('mouseup', onMouseUp);
      });
      dragHorizontalSecond.addEventListener('mousedown', (e:any) => {
        startTopHeight = zoneASecond.getBoundingClientRect().height;
        startBottomHeight = zoneBSecond.getBoundingClientRect().height;
        nativeElement.addEventListener('mousemove', onMouseMoveHorizontalSecond);
        nativeElement.addEventListener('mouseup', onMouseUp);
      });
      //This is applied when there is no order on the Vertcal Second
      /* dragVerticalSecond.addEventListener('mousedown', (e:any) => {
        startLeftWidth = zoneASecond.getBoundingClientRect().width;
        startRightWidth = zoneBSecond.getBoundingClientRect().width;
        nativeElement.addEventListener('mousemove', onMouseMoveVerticalSecond);
        nativeElement.addEventListener('mouseup', onMouseUp);
      }); */
      dragVerticalSecond.addEventListener('mousedown', (e:any) => {
        startLeftWidth = zoneBSecond.getBoundingClientRect().width;
        startRightWidth = zoneASecond.getBoundingClientRect().width;
        nativeElement.addEventListener('mousemove', onMouseMoveVerticalSecond2);
        nativeElement.addEventListener('mouseup', onMouseUp);
      });
  
      
    }

 }
