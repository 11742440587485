import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-raw',
  templateUrl: './edit-raw.component.html',
  styleUrls: ['./edit-raw.component.scss']
})
export class EditRawComponent {

}
