<div>
    <div style="width: 100%; height: 30px; background-color: var(--primary); border-bottom: 3px solid #9DB2BF; ">
        <div class="container" style="max-width: 100% !important; color: white;">
            <div fxLayout="row">
                <div class="center-vertical" class="profile" fxFlex="10%"><img src="assets/logos/icon_enovsky.png"></div>
                <div class="center-vertical" fxFlex="30%" style="white-space: nowrap;">
                    Content Splitter
                </div>
                <div class="center-vertical" fxFlex="40%">
                    <nav style="z-index: 10000;">
                        <ul style="margin: 0; display: flex;" fxLayoutAlign="center center"> 
                            <li> <mat-icon style="color: white; cursor: pointer; margin-right: 20px; font-size: 20px;" aria-hidden="false" fontIcon="content_cut" title="Apply"></mat-icon>
                                <ul> 
                                    <li (click)="splittingModule.computeSplit()" style="white-space: nowrap;">Build Split</li>
                                    <li (click)="splittedModule.showSplitted()" style="white-space: nowrap;">Show Splited</li>
                                    <li (click)="splittingModule.showSplitting()" style="white-space: nowrap;">Show Split Board</li>
                                </ul> 
                            </li> 
                            <li> <mat-icon style="color: white; cursor: pointer; margin-right: 20px; font-size: 20px" aria-hidden="false" fontIcon="cleaning_services" title="Mop"></mat-icon>
                                <ul> 
                                    <li (click)="splittedModule.computeClean()" style="white-space: nowrap;">Build Clean</li>
                                    <li (click)="cleanedModule.showCleaned()" style="white-space: nowrap;">Show Cleaned</li> 
                                </ul> 
                           </li>
                           <li> <mat-icon (click)="classifyModule.showClassified()" style="color: white; cursor: pointer; margin-right: 20px;" aria-hidden="false" fontIcon="hive" title="Identify"></mat-icon>
                                
                           </li> 
                        </ul> 
                    </nav>
                </div>
                <div fxFlex="20%" style="display: flex; align-items: center;">
                    <div style="margin-left: auto;">
                        <mat-icon style="color: white; cursor: pointer; margin-right: 20px; font-size: 20px" aria-hidden="false" fontIcon="cloud_upload" title="Upload"></mat-icon>
                        <mat-icon style="color: white; cursor: pointer; font-size: 20px" aria-hidden="false" fontIcon="close" title="Close" (click)="onClose()"></mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <mat-progress-bar *ngIf="inProgress" class="progress_bar" mode="buffer"></mat-progress-bar>
        
    </div>
    <div *ngIf="splittingActive" class="pages show_content">
        <div class="show_content_to_split" style="overflow: auto;"> </div>
        <div class="show_content_split_resume" style="overflow: auto;"></div>
    </div>
    <div *ngIf="splittedActive" class="pages show_content_splited" style="overflow: auto;"> </div>
    <div *ngIf="cleanedActive" class="pages show_content_cleaned" style="overflow: auto;"> </div>
    <div *ngIf="classifiedActive">
        <app-project-classify [userInfos]="userInfos" [classifyData]="classifyData" [projectInfos]="projectInfos" [platformInfos]="platformInfos"></app-project-classify>
    </div>
</div>