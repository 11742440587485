import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import config from 'config/default.json';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';

@Component({
  selector: 'app-vca-create-project',
  templateUrl: './vca-create-project.component.html',
  styleUrls: ['./vca-create-project.component.scss']
})
export class VcaCreateProjectComponent{
  constructor(
    private elRef:ElementRef,
    public cdRef: ChangeDetectorRef,
    private apiService: ApiServiceModule,
    private sharedService: SharedService) {
  }
  @Input() userInfos: { [x:string]: any } = new Map();
  pageId = 'vca-create-project'
  pdfData: { [x:string]: any } = new Map();
  modes:{ [x:string]: string|number }[] = [{id:1, key: 'raw', value: 'Raw'}, {id: 2, key: 'splited', value: 'Splite'}];

  dataSource:{[x: string]: any}[] = [];
  platformInfos:{[x: string]: any} = {origin: 'virtualcampus', key: 'vca', root: 'VIRTUALCAMPUS'}

  ngOnInit(): void {

  }
  loadSplitContentData(params:any){
    this.sharedService.sendData({in_progress: true});
    params['mode'] = (params['mode'] && params['mode'].length)? this.modes.filter((row:any) => params['mode'].includes(row['id'])).map(el => el['key']): this.modes.map(el => el['key']);
    let data = {
        table: 'load',
        filter_info: params
    }; 
    if(params.key){
      this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/raw-content/load_vca', data).subscribe((response)=>{
        this.dataSource = response;
        this.sharedService.sendData({in_progress: false});
      })
    }
    
  }
}
