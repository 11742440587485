<div fxLayout="row" style="padding: 20px 10px 20px 10px;">
    <div class="center-vertical" fxFlex="20%">
        <p>Total: {{this.showedRows.length}}</p>
    </div>
    <div class="center-vertical" fxFlex="40%" fxLayoutAlign="center center">
        <button type="button" class="button-secondary-small" (click)="rawSetting()"  style="white-space: nowrap; align-items: center; display: flex; margin-right: 3px;">
            <mat-icon fontIcon="settings" style="color: gray; cursor: pointer; margin-right: 5px; font-size: 20px; width: fit-content; height: fit-content;"></mat-icon>
            <div>Contents Setting</div>
        </button>
    </div>
    <div class="center-vertical" fxFlex="40%" style="display: flex; align-items: center;">
        <div style="margin-left: auto;">
            <input class="col-input"  (keyup)="applyFilterLocal($event.target, 'all_column')" placeholder="Search">
        </div>
    </div>
</div>
<div class="table_div">
    <table>
        <thead>
            <tr>
                <th><div class="column-name">Id</div></th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'id', 'number')"></mat-icon>
                    <div class="column-name">Id Content</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'id')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'supplier', 'string')"></mat-icon>
                    <div class="column-name">Supplier</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'supplier')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'origin_name', 'string')"></mat-icon>
                    <div class="column-name">Origin name</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'origin_name')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'size', 'string')"></mat-icon>
                    <div class="column-name">Size</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'size')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'file_name', 'string')"></mat-icon>
                    <div class="column-name">Name</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'file_name')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'format', 'string')"></mat-icon>
                    <div class="column-name">Format</div>
                    <mat-select placeholder="Choose" style="height: 22px;" (selectionChange)="applyFilterLocal($event, 'format')">
                        <mat-option [value]="''">ALL</mat-option>
                        <mat-option [value]="el" *ngFor="let el of formats">{{el}}</mat-option>
                    </mat-select>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'state', 'string')"></mat-icon>
                    <div class="column-name">State</div>
                    <mat-select placeholder="Choose" style="height: 22px;" (selectionChange)="applyFilterLocal($event, 'state')">
                        <mat-option [value]="''">ALL</mat-option>
                        <mat-option [value]="'raw'">Raw</mat-option>
                        <mat-option [value]="'splited'">Splited</mat-option>
                    </mat-select>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'created_date', 'date')"></mat-icon>
                    <div class="column-name">Created date</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'created_date')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'id_project', 'string')"></mat-icon>
                    <div class="column-name">Belongs to project</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'id_project')"></div>
                </th>
                <th>
                    <div style="display: flex; justify-content: center;"><input #checkedAllRef type="checkbox" class="form-check-input" style="font-size:large; cursor: pointer; " [disabled]="true" (change)="selectAll($event.target)"></div>
                </th>
            </tr>
        </thead>
        <tbody #row_tbody *ngFor="let row of showedRows; let i = index">
            <tr (click)="toggleRow(row, $event.target, row_tbody)">
                <td>{{ i + 1 }}</td>
                <td>{{ row['id'] }}</td>
                <td style="white-space: nowrap;">{{ row['supplier'] }}</td>
                <td>{{ row['origin_name'] }}</td>
                <td style="white-space: nowrap;">{{ row['size'] }}</td>
                <td>{{ row['file_name'] }}</td>
                <td>{{ row['format'] }}</td>
                <td>{{ row['state'] }}</td>
                <td style="white-space: nowrap;">{{ row['created_date'] }}</td>
                <td>{{ row['id_project']}}</td>
                <td class="to_skip_element">
                    <input type="checkbox" [checked]="row['checked']" class="form-check-input" style="font-size:large; cursor: pointer; " [disabled]="row['id_project']" (change)="selectRow($event.target, i)">
                </td>
            </tr>
            <tr class="expand_detail" *ngIf="row['expanded']">
                <td colspan="12">
                    <app-pdf-viewer [pdfData]="pdfData" class="pdf-container"></app-pdf-viewer>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="showedRows.length === 0">
            <tr ><td colspan="14" style="text-align: center;">No matching records found</td></tr>
        </tbody>
    </table>
</div>