import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-vca-student-dashboard',
  templateUrl: './vca-student-dashboard.component.html',
  styleUrls: ['./vca-student-dashboard.component.scss']
})
export class VcaStudentDashboardComponent {
  @Input() userInfos: { [x:string]: any } = new Map();
  ngOnInit(): void {

  }
}
