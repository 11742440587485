<div *ngIf="page=='confirmation' " class="modal-dialog modal-xl modal-content">
    <div class="modal-header">
        <div><h2 class="modal-title" >Caution !!!</h2></div>
    </div>
    <div class="modal-body">
        <div>{{data['text']}}</div>
    </div>
    <hr>
    <div class="modal-footer" style="margin-top: 5px;">
        <button type="button" class="button-secondary-small left-side" (click)="onClose()">Close</button>
        <button type="button" class="button-validate-small" (click)="validateAction()">Continue</button>
    </div>
</div>


<div *ngIf="page=='new_project' " class="modal-dialog modal-xl modal-content">
    <div class="modal-header">
        <div><h2 class="modal-title" >Create new Project</h2></div>
    </div>
    <div class="modal-body">
        <input #project_name type="text" class="col-input" placeholder="Type name" value="">
    </div>
    <hr>
    <div class="modal-footer" style="margin-top: 5px;">
        <button type="button" class="button-secondary-small left-side" (click)="closeProjectCreation()">Close</button>
        <button type="button" class="button-validate-small" (click)="sendProjectName(project_name.value)">Continue</button>
    </div>
</div>

<div *ngIf="page=='video_show' " class="modal-dialog modal-xl modal-content">
    <div class="modal-header">
        <div><h2 class="modal-title" >{{data['title']}}</h2></div>
    </div>
    <div id="demo_container" class="modal-dialog modal-xl modal-content" >
        <video controls preload="metadata" style="width: 100%; height: 50%" loop autoplay>
          <source src="{{data['url']}}">video can't play
        </video>
    </div>
    <hr>
</div>






