import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBar } from "@angular/material/snack-bar";



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class HelperModule { 
  constructor(
    private snackBar: MatSnackBar
    ) {}
  showToast(message: string){
    this.snackBar.open(message, 'OK', {
      duration: 2000,
      verticalPosition: "top",
      horizontalPosition: "right" 
    });
  }
}
