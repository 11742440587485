<div class="modal-dialog modal-xl modal-content">
    <div class="modal-header">
        <div><h2 class="modal-title">Connect to an other </h2></div>
    </div>
    <div class="modal-body">
        <div fxLayout="row">
            <div class="label-style" fxFlex="20%">Connect as</div>
            <div fxFlex="80%">
                <mat-select placeholder="User" [formControl]="userControl">
                    <input class="col-input" [formControl]="userInputControl">
                    <mat-option [value]="0">Choose</mat-option>
                    <mat-option [value]="result['id_user']" *ngFor="let result of allUsers; let i = index">{{result['first_name']}} {{result['last_name']}} ({{result['email']}})</mat-option>
                </mat-select>
            </div>
        </div>
    </div>
    <hr>
    <div class="modal-footer" style="margin-top: 5px;">
        <button type="button" class="button-secondary-small left-side" (click)="closeUserConnexion()">Close</button>
        <button type="button" class="button-validate-small" (click)="sendUserConnexion()">Connect</button>
    </div>
</div>