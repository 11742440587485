import { ChangeDetectorRef, Component, ElementRef, Input, SimpleChanges } from '@angular/core';
import { TableComponent } from '../table/table.component';
import { SharedService } from '../../modules/shared-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import config from 'config/default.json';
import { ApiServiceModule } from '../../modules/api-service.module';
import { HelperModule } from '../../modules/helper.module';
import { SettingPromotionComponent } from './setting-promotion/setting-promotion.component';
@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss', '../../components/table/table.component.scss']
})
export class PromotionComponent extends TableComponent{
  constructor(
    private elRef:ElementRef,
    private cdRef: ChangeDetectorRef,
    public helper: HelperModule,
    public dialog: MatDialog,
    private apiService: ApiServiceModule,
    private sharedService: SharedService,
    public sanitizer: DomSanitizer) {
      super(sanitizer);
  }
  @Input() userInfos: { [x:string]: any } = new Map();
  @Input() promotionData:{[x: string]: any}[] = [];
  @Input() productData:{[x: string]: any}[] = []
  @Input() platformInfos: { [x:string]: any } = new Map();
  promoTypes: any[] = [];

  ngOnInit() {
    this.setData();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['promotionData'] && !changes['promotionData'].firstChange) {
      this.setData();
    }
  }
  setData(){
    this.dataSource = this.promotionData.map((row) => {
      return row;
    });
    this.promoTypes = Array.from(new Set(this.promotionData.map(el=>el['promo_type'])));
    this.showedRows = this.dataSource;
  }
  loadPromotionData(){
    this.sharedService.sendData({in_progress: true});
    this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/promotion/load', {id_user: this.userInfos['id_user'], platform_infos: this.platformInfos}).subscribe((response: any)=>{
      this.dataSource = response['promotion'];
      this.showedRows = this.dataSource;
      this.sharedService.sendData({in_progress: false});
    })
  }
  settingPromotion(action:string, rowInfos: any){
    const dialogRef = this.dialog.open(SettingPromotionComponent, {
      width: '70%',
      position: {top: '30px' },
      data: {action: action, product: this.productData, row_infos: rowInfos, user_infos: this.userInfos, platform_infos: this.platformInfos}
    });
    dialogRef.afterClosed().subscribe((response:any) => {
      if(response && response.action == 'delete_promo'){
        this.dataSource = this.dataSource.filter((el:any)=> !response.delete_rows.includes(el.id));
        this.showedRows = this.showedRows.filter((el:any)=> !response.delete_rows.includes(el.id));
      }
    });
  }
  setUpdatePromotion(data:any){
    this.dataSource = this.dataSource.map((element:any) => {
      if (element.id == data.id) {
        element.begin_date = data.begin_date;
        element.expiration_date = data.expiration_date;
      }
      return element;
      });

    this.showedRows = this.showedRows.map((element:any) => {
      if (element.id == data.id) {
        element.begin_date = data.begin_date;
        element.expiration_date = data.expiration_date;
      }
      return element;
      });
  }
  
  toggleRow(row:any, clickedElm:any, rowTab:any){
    let toExpand = true;
    for (var tdTab of rowTab.querySelectorAll('.to_skip_element')){
      if(tdTab.contains(clickedElm)){
        toExpand = false;
        break;
      }
    }
    if(toExpand){
      row['expanded'] =! row['expanded'];
    }
  }
  applyFilterLocal(element:any, column:string){
    let tableBody = this.elRef.nativeElement.querySelector('tbody');
    this.removeDetailsRows(tableBody);
    this.applyFilter(element.value, column);
    this.cdRef.detectChanges();
  }
  applySortLocal(element:any, column:string, type:string){
    let tableBody = this.elRef.nativeElement.querySelector('tbody');
    this.removeDetailsRows(tableBody);
    this.applySort(element, column, type);
    this.cdRef.detectChanges();
  }
  setWaiting(value:any){
    this.sharedService.sendData({in_progress: value});
  }
}
