<div class="registration-container">
    <div class="page-inscription-header">
        <h1 style="margin-block-end: 0px; text-align: center;">Registration</h1> 
        <p class="page-inscription__subtitle">Fill the form to create an account</p>
    </div>
    <div [formGroup]="formGroup" class="registration-content" style="position: relative;">
        
        <div class="separator"></div>
        <div fxLayout="row" fxLayout.xs="column">
            <div class="class-mat-field-left" fxFlex="50" fxLayoutAlign="center">
                <div style="width: 100%;">
                    <div class="field-label">First Name <span style="color: red;">*</span></div>
                    <input type="text" class="col-input" formControlName="first_name" placeholder="name" matInput />
                    <mat-error *ngIf="formGroup.get('first_name')!.hasError('required') && formGroup.get('first_name')!.touched" class="error-label">This field should not be empty</mat-error>
                </div>
            </div>
            <div class="separator"></div>
            <div class="class-mat-field-right" fxFlex="50" fxLayoutAlign="center">
                <div style="width: 100%;">
                    <div class="field-label">Last Name <span style="color: red;">*</span></div>
                    <input type="text" class="col-input" formControlName="last_name" placeholder="surname" matInput />
                    <mat-error *ngIf="formGroup.get('last_name')!.hasError('required') && formGroup.get('last_name')!.touched" class="error-label">This field should not be empty</mat-error>
                </div>
            </div>
        </div>
        <div class="separator"></div>
        <div fxLayout="row" fxLayout.xs="column">
            <div class="class-mat-field-left" fxFlex="50" fxLayoutAlign="center">
                <div style="width: 100%;">
                    <div class="field-label">Address mail <span style="color: red;">*</span></div>
                    <input type="text" class="col-input" formControlName="mail" placeholder="mail" matInput />
                    <mat-error *ngIf="formGroup.get('mail')!.hasError('pattern') && formGroup.get('mail')!.touched" class="error-label">Address mail format incorrect</mat-error>
                </div>
            </div>
            <div class="separator"></div>
            <div class="class-mat-field-right" fxFlex="50" fxLayoutAlign="center">
                <div style="width: 100%;">
                    <div class="field-label">Phone Number <span style="color: red;">*</span></div>
                    <input type="tel" class="col-input" formControlName="phone" placeholder="phone" matInput />
                </div>
                
            </div>
        </div>
        <div class="separator"></div>
        <div fxLayout="row" fxLayout.xs="column">
            <div class="class-mat-field-left" fxFlex="50" fxLayoutAlign="center">
                <div style="width: 100%;">
                    <div class="field-label">Password <span style="color: red;">*</span></div>
                    <input type="password" class="col-input" formControlName="password" placeholder="password" matInput />
                    <mat-error *ngIf="formGroup.get('password')!.hasError('pattern') && formGroup.get('password')!.touched" class="error-label">The password should be more than 6 characters</mat-error>
                </div>
            </div>
            <div class="separator"></div>
            <div class="class-mat-field-right" fxFlex="50" fxLayoutAlign="center">
                <div style="width: 100%;">
                    <div class="field-label">Confirm Password <span style="color: red;">*</span></div>
                    <input type="password" class="col-input" formControlName="confirm_pass" placeholder="confirm password" matInput />
                    <mat-error *ngIf="formGroup.get('confirm_pass')!.hasError('pattern') && formGroup.get('confirm_pass')!.touched" class="error-label">The password should be more than 6 characters</mat-error>
                </div>
                
            </div>
        </div>
        <div class="separator"></div>
        <div class="separator"></div>
        <div style="height: 50px;" fxLayoutAlign="center center">
            <button class="button-validate" (click) = "registerUser()">Register</button>
        </div>
        <div class="separator"></div>
        <p>Already have an account ? <a class="request_account" (click) = "connectUser()">Connexion</a></p>
        
    </div>
</div>