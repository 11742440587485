import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';

@Component({
  selector: 'app-side-virtualclass',
  templateUrl: './side-virtualclass.component.html',
  styleUrls: ['./side-virtualclass.component.scss']
})
export class SideVirtualclassComponent {
  constructor(
    private elRef:ElementRef,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private sharedService: SharedService
    ) {}
  @Input() userInfos:  { [x:string]: any } = new Map();
  virtualClassPages = [{id_page: 'vcl-content-project', name: 'Edit Content', 'icon': 'edit_document'}, {id_page: 'vcl-create-project', name: 'Create Project', icon: 'add_notes'}, {id_page: 'vcl-upload-content', name: 'Upload Content', icon: 'upload'}, {id_page: 'vcl-content', name: 'Manage Content', icon: 'auto_stories'}, {id_page: 'vcl-manage-department', name: 'Manage Department', icon: 'school'},
    {id_page: 'vcl-promotion', name: 'Promotion', 'icon': 'thumb_up_off_alt'}, {id_page: 'vcl-advertisment', name: 'Advertisment', 'icon': 'campaign'},
  ];
  activePage = 'vcl-content-project';
  ngOnInit(){
    let authorizations = this.userInfos?.['authorization']?.virtualclass || [];
    this.virtualClassPages = authorizations.length? this.virtualClassPages.filter((item:any)=> authorizations.includes(item.id_page)): [];
    this.activePage = this.userInfos?.['navigation_infos']?.['vcl_last_page'] || this.activePage;
  }
  
  showHidePage(pageId:string){
    this.activePage = pageId;
    this.sharedService.sendData({side_class_page_id: pageId, close_side_nav: true});

  }
}
