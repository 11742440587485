import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
import config from 'config/default.json';
import { DialogComponent } from 'src/app/back-office/components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UploadContentComponent } from 'src/app/back-office/components/upload-content/upload-content.component';

@Component({
  selector: 'app-vca-upload-content',
  templateUrl: './vca-upload-content.component.html',
  styleUrls: ['./vca-upload-content.component.scss']
})
export class VcaUploadContentComponent {
  constructor(
    private elRef:ElementRef,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private apiService: ApiServiceModule,
    private helper: HelperModule,
    public dialog: MatDialog,
    private sharedService: SharedService
    ) {}
  @Input() userInfos: { [x:string]: any } = new Map();
  @ViewChild('appUploadContent', { static: true }) appUploadContent!: UploadContentComponent;
  pageId = 'vca-upload-content'
  modes:{ [x:string]: string|number }[] = [{id:1, key: 'raw', value: 'Raw'}, {id: 2, key: 'video', value: 'Video'}];
  platformInfos:{[x: string]: any} = {origin: 'virtualcampus', key: 'vca', root: 'VIRTUALCAMPUS'}
  filterData: {[x: string]: any} = {}

  ngOnInit(){
    //console.log(this.userInfos)
  }
  filterUploadContent(filterData: any){
    console.log(filterData)
    filterData['mode'] = filterData['mode'] && filterData['mode'].length? this.modes.filter((row:any) => filterData['mode'].includes(row['id'])).map(el => el['key']): this.modes.map(el => el['key']);
    let parents = filterData.parents;
    if(parents.structure && parents.structure.length == 1 && filterData.value.length){
      let importPathList = [parents.country?.[0]?.name || '', parents.institution?.[0]?.name || '', parents.structure?.[0]?.name || ''];
      let importPath =  importPathList.filter((el:any)=>el != '')
      let userInfos = this.userInfos;
      userInfos['navigation_infos'] = null;
      let data = {
        structure_id: parents.structure[0].id,
        file_path_list: importPath,
        user_infos: userInfos,
        platform_infos: this.platformInfos
      }
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '60%',
        position: {top: '30px' },
        data: {page: 'confirmation', text: 'Your are about to upload contents inside: '+importPath.join(' > '), }
      });
      dialogRef.afterClosed().subscribe((toContinue:boolean) => {
        if(toContinue){
          this.appUploadContent.uploadContent(data);
        }
      });
    }else this.helper.showToast("Your filter is empty or contain some muliselects..."); 
  }
}
