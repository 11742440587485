import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
import config from 'config/default.json';
import { DialogComponent } from 'src/app/back-office/components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UploadContentComponent } from 'src/app/back-office/components/upload-content/upload-content.component';

@Component({
  selector: 'app-vcl-upload-content',
  templateUrl: './vcl-upload-content.component.html',
  styleUrls: ['./vcl-upload-content.component.scss']
})
export class VcUploadContentComponent {
  constructor(
    private elRef:ElementRef,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private apiService: ApiServiceModule,
    private helper: HelperModule,
    public dialog: MatDialog,
    private sharedService: SharedService
    ) {}
  @Input() userInfos: { [x:string]: any } = new Map();
  @ViewChild('appUploadContent', { static: true }) appUploadContent!: UploadContentComponent;
  pageId = 'vcl-upload-content'
  modes:{ [x:string]: string|number }[] = [{id:1, key: 'raw', value: 'Raw'}, {id: 2, key: 'video', value: 'Video'}];
  platformInfos:{[x: string]: any} = {origin: 'virtualclass', key: 'vcl', root: 'VIRTUALCLASS'}
  filterData: {[x: string]: any} = {}

  ngOnInit(){

  }
  filterUploadContent(selectedData: any){
    let filterData = selectedData.filter_data;
    let modeSelected = selectedData.mode;
    modeSelected = modeSelected?.length? this.modes.filter((row:any) => modeSelected.includes(row['id'])).map(el => el['key']): this.modes.map(el => el['key']);
    let idCycle = filterData.cycle?.id || null;
    let idCountry = filterData.country?.id || null;
    let idRegion = filterData.region?.id || null;
    let idInstitution = filterData.institution?.id || null;

    if(selectedData.action == 'user' && idCycle && idCountry){
      let importPathName = [filterData.country?.name || '', filterData.cycle?.name || '', filterData.region?.name || '', filterData.institution?.name || ''];
      let importPath =  importPathName.filter((el:any)=>el != '')
      let userInfos = this.userInfos;
      userInfos['navigation_infos'] = null;
      this.sharedService.sendData({in_progress: true});
      this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/raw-content/get_suppliers', this.platformInfos).subscribe((response)=>{
        this.sharedService.sendData({in_progress: false});
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '60%',
          position: {top: '30px' },
          data: {page: 'upload_content', suppliers: response, text: 'Your are about to upload contents inside: '+importPath.join(' > '), }
        });
        dialogRef.afterClosed().subscribe((result:any) => {
          let data = {
            supplier_name: result.supplier_name,
            id_cycle: idCycle,
            id_country: idCountry,
            id_region: idRegion,
            id_vcl_institution: idInstitution,
            file_path_list: importPath,
            user_infos: userInfos,
            platform_infos: this.platformInfos
          }
          if(result.confirm){
            this.appUploadContent.uploadContent(data);
          }
        });
      });
    }else this.helper.showToast("Your filter is empty or contain some muliselects...");
  }
}
