<div>
    <div style="width: 100%; height: 30px; background-color: var(--primary); border-bottom: 3px solid #9DB2BF; ">
        <div class="container" style="max-width: 100% !important; color: white;">
            <div fxLayout="row">
                <div class="center-vertical" class="profile" fxFlex="10%"><img src="assets/logos/icon_enovsky.png"></div>
                <div class="center-vertical" fxFlex="30%" style="white-space: nowrap;">{{taskLabels[currentTask]}}</div>
                <div *ngIf="source == 'content'" class="center-vertical" fxFlex="20%">Update</div>
                <div *ngIf="source == 'project'" class="center-vertical" fxFlex="20%">
                    <nav style="z-index: 10000;">
                        <ul style="margin: 0; display: flex;" fxLayoutAlign="center center"> 
                            <li> <mat-icon (click)="cleanedModule.showCleaned()" style="cursor: pointer; margin-right: 20px; font-size: 20px" [ngClass]="cleanedActive? 'page-active': 'page-inactive'" aria-hidden="false" fontIcon="cleaning_services"></mat-icon>
                                <ul> <li>Cleaned</li></ul> 
                            </li>
                            <li> <mat-icon (click)="editorModule.showEdited()" style="cursor: pointer; margin-right: 20px; font-size: 20px" [ngClass]="editedActive? 'page-active': 'page-inactive'" aria-hidden="false" fontIcon="edit_note"></mat-icon>
                                <ul> <li>Edit</li></ul> 
                            </li>
                            <li> <mat-icon (click)="classifyModule.showClassified()" style="cursor: pointer; margin-right: 20px;" [ngClass]="classifiedActive? 'page-active': 'page-inactive'" aria-hidden="false" fontIcon="hive"></mat-icon>
                                <ul> <li>Classified</li></ul>     
                            </li> 
                        </ul> 
                    </nav>
                </div>
                <div class="center-vertical" fxFlex="20%">
                    <nav style="z-index: 10000;">
                        <ul style="margin: 0; display: flex; "> 
                            <li *ngIf="editedActive"> <mat-icon style="color: white; cursor: pointer; margin-right: 20px; font-size: 20px;" aria-hidden="false" fontIcon="video_label" title="Identify"></mat-icon>
                                <ul style="right: -4px;"> 
                                    <li (click)="editorModule.setOrientation('horizontal')" style="white-space: nowrap; display: flex; ">
                                        <mat-icon [ngClass]="editorModule.orientation == 'horizontal'? 'orientation-icon-active': 'orientation-icon-inactive'" style="cursor: pointer; margin-right: 5px; font-size: 18px; width: fit-content; height: fit-content;" aria-hidden="false" fontIcon="splitscreen" title="Horizontal"></mat-icon>
                                        <div>Horizontal Orientation</div>
                                    </li>
                                    <li (click)="editorModule.setOrientation('vertical')" style="white-space: nowrap; display: flex;">
                                        <mat-icon [ngClass]="editorModule.orientation == 'vertical'? 'orientation-icon-active': 'orientation-icon-inactive'" style="cursor: pointer; margin-right: 5px; font-size: 18px; width: fit-content; height: fit-content; transform: rotate(90deg);" aria-hidden="false" fontIcon="splitscreen" title="Vertical"></mat-icon>
                                        <div>Vertical Orientation</div>
                                    </li>
                                </ul> 
                            </li> 
                            <li *ngIf="editedActive"> <mat-icon style="cursor: pointer; margin-right: 20px;" aria-hidden="false" fontIcon="help"></mat-icon>
                                <ul style="width: 500px; right: -200px; padding: 10px; color: black; box-shadow: inset 0 0 12px 12px whitesmoke, inset 0 0 3px 2px whitesmoke;"> 
                                    <strong>Style de Saisie:</strong>
                                    <p>Police: Times New Roman</p>
                                    <p>Taille: 13</p>

                                    <strong>Sauvegarde d'édition:</strong>
                                    <p>Pour être sauvegardé, penser à cliquer sur le bouton de sauvegarde, vous pouvez revenir à tout moment continuer</p>
                                    <strong>Validation d'édition:</strong>
                                    <p>Une fois la saise complète d'un contenu activez le checkbox Edited afin qu'il soit considéré comme saisie.</p>
                                </ul>     
                            </li> 
                            <li *ngIf="classifiedActive"> <mat-icon style="cursor: pointer; margin-right: 20px;" aria-hidden="false" fontIcon="help"></mat-icon>
                                <ul style="width: 500px; right: -200px; padding: 10px; color: black; box-shadow: inset 0 0 12px 12px whitesmoke, inset 0 0 3px 2px whitesmoke;"> 
                                    <strong>Import de Contenu</strong>
                                    <p>Pour être importé, le contenu doit avoir l'état: classified et edited, et être du statut new.</p>
                                    <p>Pour les corrigés il faut impérativement qu'il soit rattaché à son sujet</p>

                                    <strong>Reimport de Contenu</strong>
                                    <p>Si un contenu est déjà importé, pour le réimporter, il faut d'abord changer son statut de imported à new</p>
                                    <strong>Dupliquer un Contenu</strong>
                                    <p>Dans certaines situations, le contenu et sa correction sont au même endroit, il est donc possible de dupliquer le contenu afin de créer la déclinaison de correction</p>
                                </ul>     
                            </li> 
                            <li *ngIf="userInfos['privilege'] >= 3"> <mat-icon style="color: white; cursor: pointer; margin-right: 20px; font-size: 20px;" aria-hidden="false" fontIcon="more_vert" title="Settings"></mat-icon>
                                <ul style="right: -4px;"> 
                                    <li (click)="classifyModule.deleteProject('delete_project_without_ressource')" style="white-space: nowrap; display: flex; ">
                                        <div>Delete Project withtout Ressources</div>
                                    </li>
                                    <li (click)="classifyModule.deleteProject('delete_project_with_ressource')" style="white-space: nowrap; display: flex;">
                                        <div>Delete Project with Ressources</div>
                                    </li>
                                </ul> 
                            </li> 
                        </ul> 
                    </nav>
                </div>
                <div fxFlex="20%" style="display: flex; align-items: center;">
                    <div style="margin-left: auto;">
                        <mat-icon *ngIf="cleanedActive" (click)="buildProcess()" style="color: white; cursor: pointer; margin-right: 40px;" aria-hidden="false" fontIcon="settings" title="Build"></mat-icon>
                        <mat-icon style="color: white; cursor: pointer; font-size: 20px" aria-hidden="false" fontIcon="close" title="Close" (click)="onClose()"></mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <mat-progress-bar *ngIf="inProgress" class="progress_bar" mode="buffer"></mat-progress-bar>
        
    </div>
    <div *ngIf="cleanedActive" class="pages show_content_cleaned" style="overflow: auto;"> </div>
    <div *ngIf="classifiedActive">
        <app-project-classify [userInfos]="userInfos" [classifyData]="classifyData" [projectInfos]="projectInfos" [platformInfos]="platformInfos"></app-project-classify>
    </div>
    <div *ngIf="editedActive" class="pages resizable-container-vertical">
        <div *ngIf="source == 'project'" class="resizable-zone-vertical" id="left-vertical" [ngStyle]="{'width': widthListZone + 'px'}">
            <h2 style="padding-left: 50px;">Contents</h2>
            <div class="list-group">
                <ul style="padding-left: 0px; height: 90vh; overflow-x: hidden;">
                    <li [ngClass]="{'active' : content['id_content'] == editorModule.numContent}" class="list-group-item hover-title" style="display: flex;" [attr.data-tooltip]="content['id_content'].toString()+ '-'+ content['content_name']" *ngFor="let content of contentData; let i = index" (click)="editorModule.displayEditor(content, content['id_content'])">
                        <mat-icon style="cursor: pointer; margin-left: -10px; bottom: -20px; padding-right: 24px; margin-bottom: -5px; margin-right: 3px; overflow: none;" [ngClass]="content['state'].includes('edited')? 'cleaned-active': 'cleaned-inactive'" aria-hidden="false" fontIcon="check_small"></mat-icon>
                        <span class="text-style">{{content['id_content'].toString()}}- {{content['content_name']}}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="resizable-bar-vertical" id="drag-vertical"></div>
        <div class="resizable-zone-vertical" id="right-vertical" [ngStyle]="{'width': 'calc(100% - '+widthListZone+'px)'}">
            <div [ngClass]="editorModule.orientation == 'horizontal'? 'resizable-container-horizontal-second': 'resizable-container-vertical-second'">
                <div [ngClass]="editorModule.orientation == 'horizontal'? 'resizable-horizontal-second': 'resizable-vertical-second'" id="zone-a-second">
                    <div style=" position: absolute; height: 15px; bottom: 0px; width: 100%; background-color: transparent;"></div>
                    <div style=" position: absolute; height: 100%; width: 20px; background-color: transparent;"></div>
                    <div style=" position: absolute; height: 100%; width: 20px; right: 0px; background-color: transparent;"></div>
                    <div id="editor-container"></div>
                </div>
                <div class="resizable-bar-horizontal-second" id="drag-horizontal-second"></div>
                <div class="resizable-bar-vertical-second" id="drag-vertical-second"></div>
                <div [ngClass]="editorModule.orientation == 'horizontal'? 'resizable-zone-horizontal-second': 'resizable-zone-vertical-second'" id="zone-b-second">
                    <div fxLayout="row" style="width: 100%; height: 27px; color: white; background-color: #446994; padding: 5px;">
                        <div class="center-vertical" fxFlex="30%">
                            <span>Edited</span>
                            <input #is_cleaned class="form-check-input" [checked]="editorModule.selectedContent['state'].includes('edited')" style="font-size:large; cursor: pointer; " type="checkbox" role="switch" id="is_cleaned" (change)="editorModule.updateState(editorModule.numContent, is_cleaned.checked)">
                        </div>
                        <div class="center-vertical hover-title" fxFlex="30%" [attr.data-tooltip]="(editorModule.numContent).toString()+ '-'+ editorModule.selectedContent['content_name']">
                            <div class="text-style" style="font-size: small;">{{(editorModule.numContent).toString()}}- {{ editorModule.selectedContent['content_name'] | slice:0:50 }}{{ editorModule.selectedContent['content_name']?.length > 30 ? '...' : ''}}</div>
                        </div>
                        <div class="center-vertical" fxFlex="20%">
                            <nav>
                                <ul style="margin: 0; display: flex;"> 
                                    <li> <mat-icon style="color: white; cursor: pointer; margin-right: 20px; font-size: 20px;" aria-hidden="false" fontIcon="auto_stories" title="Identify"></mat-icon>
                                        <ul>
                                            <li (click)="editorModule.switchPDFMode('raw')" style="white-space: nowrap; display: flex; ">
                                                <mat-icon [ngClass]="editorModule.pdfToShow == 'raw'? 'content_state-active': 'content_state-inactive'" style="cursor: pointer; margin-right: 5px; font-size: 20px; width: fit-content; height: fit-content;" aria-hidden="false" fontIcon="check"></mat-icon>
                                                <div>Raw</div>
                                            </li> 
                                            <li *ngIf="source == 'project'" (click)="editorModule.switchPDFMode('cleaned')" style="white-space: nowrap; display: flex; ">
                                                <mat-icon [ngClass]="editorModule.pdfToShow == 'cleaned'? 'content_state-active': 'content_state-inactive'" style="cursor: pointer; margin-right: 5px; font-size: 20px; width: fit-content; height: fit-content;" aria-hidden="false" fontIcon="check"></mat-icon>
                                                <div>Cleaned</div>
                                            </li>
                                            <li (click)="editorModule.switchPDFMode('edited')" style="white-space: nowrap; display: flex; ">
                                                <mat-icon [ngClass]="editorModule.pdfToShow == 'edited'? 'content_state-active': 'content_state-inactive'" style="cursor: pointer; margin-right: 5px; font-size: 20px; width: fit-content; height: fit-content;" aria-hidden="false" fontIcon="check"></mat-icon>
                                                <div>Edited</div>
                                                <mat-icon *ngIf="editorModule.pdfToShow == 'edited' "  style="font-size: 20px; color: var(--primary); float: right; padding-left: 10px;; cursor: pointer;" aria-hidden="false" fontIcon="update" title="Reset" (click)="editorModule.resetEditedPDF()"></mat-icon>
                                            </li>
                                        </ul> 
                                    </li> 
                                </ul> 
                            </nav>
                        </div>
                        <div class="center-vertical" fxFlex="20%" style="display: flex;">
                            <div style="margin-left: auto;">
                                <div style="color: white;">{{editorModule.pdfToShow.charAt(0).toUpperCase() + editorModule.pdfToShow.slice(1)}}</div>
                            </div>
                        </div>
                    </div>
                    <div id="pdf_container" style="cursor: pointer; height: calc(100% - 30px);; overflow: auto;">
                        <div id="pdf_container_raw" hidden>
                            <app-pdf-viewer [pdfData]="editorModule.pdfDataRaw" class="pdf-container"></app-pdf-viewer>
                        </div>
                        <div id="pdf_container_cleaned" hidden>
                            <app-pdf-viewer [pdfData]="editorModule.pdfDataClean" class="pdf-container"></app-pdf-viewer>
                        </div>
                        <div id="pdf_container_edited" hidden>
                            <app-pdf-viewer [pdfData]="editorModule.pdfDataEdit" class="pdf-container"></app-pdf-viewer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>