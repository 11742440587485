import { Component, ElementRef, Input, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { Subject, filter } from 'rxjs';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { MatDialog } from '@angular/material/dialog';
import { ProfileEnovskyComponent } from 'src/app/back-office/enovsky/profile-enovsky/profile-enovsky.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  constructor(private route: ActivatedRoute, private router: Router,
    private sharedService: SharedService,
    private helper: HelperModule,
    public dialog: MatDialog
  ) {}
  @ViewChild('sidenav') sidenav!: MatSidenav;
  @Input() userInfos:  { [x:string]: any } = new Map();
  eventShareUserInfos: Subject<any> = new Subject<any>();
  eventShare = this.eventShareUserInfos.asObservable();

  currentRoute: string = '';
  inProgress = false;
  eventUpdateUserInfos: Subject<void> = new Subject<void>();
  connectedUser:{ [x:string]: any } = {id_user:'', user: "user", first_name: '', last_name: '', email:'', phone: '', connected: false, connect_msg: 'Connect as'};

  ngAfterViewInit() {
    this.sharedService.sendData({user_infos: this.userInfos});
    this.currentRoute = this.route.snapshot.firstChild?.routeConfig?.path || '';
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.currentRoute = this.route.snapshot.firstChild?.routeConfig?.path || '';
      this.sharedService.sendData({user_infos: this.userInfos});
    });
    this.sharedService.data.subscribe(data => {
      if(data['close_side_nav']){
        this.sidenav.close();
      };
    });
  }
  shareUserData(){
    this.sharedService.sendData({user_infos: this.userInfos});
  }
  connectAsUser(){
    console.log(this.userInfos)
    if(!this.connectedUser['connected']){
      const dialogRef = this.dialog.open(ProfileEnovskyComponent, {
        width: '70%',
        position: {top: '30px' },
        data: {page: 'connect_as'}
      });
      dialogRef.afterClosed().subscribe((response:any) => {
        if(response.confirm){
          this.connectedUser = response.connected_user;
          console.log(this.connectedUser)
          this.userInfos['id_user'] = this.connectedUser['id_user'];
          this.userInfos['user'] = this.connectedUser['user'];
          this.connectedUser['connect_msg'] = 'Disconnect from';
          this.helper.showToast("You are connected to: "+this.connectedUser['first_name']);
          this.connectedUser['connected'] = true;
          //this.userInfos['id_user'] = "HGGJKLJKJ"
          //console.log(this.userInfos)
          //this.eventsUpdateuserInfos.next();
          this.sharedService.sendData({user_infos: this.userInfos});
        }
      });
    }else{
      this.userInfos['id_user'] = this.userInfos['root_id_user'];
      this.userInfos['user'] = this.userInfos['root_user'];
      this.helper.showToast("You are disconnected from: "+this.connectedUser['first_name']);
      this.connectedUser = {id_user:'', user: "user", first_name: '', last_name: '', email:'', phone: '', connected: false, connect_msg: 'Connect as'};
      this.connectedUser['connected'] = false;
      //this.eventsUpdateuserInfos.next();
      this.sharedService.sendData({user_infos: this.userInfos});
      
    }
    
  }
  signOut(){
    console.log(this.userInfos)
  }
  
}
