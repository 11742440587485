<div class="modal-dialog modal-xl modal-content">
    <div class="modal-header">
        <div><h2 class="modal-title" *ngIf="action == 'create_advertisment'">Create new Advertisment</h2>
            <h2 class="modal-title" *ngIf="action == 'delete_advertisment'">Delete  Advertisment «{{rowInfos['name']}}»</h2>
        </div>
    </div>
    <div class="modal-body">
        <div fxLayout="row" fxLayout.xs="column" *ngIf="action == 'create_advertisment'">
            <div fxFlex="50%" style="padding: 0 10px 0 10px;">
                <div fxLayout="row" class="element-style">
                    <div class="label-style" fxFlex="30%">Name</div>
                    <div fxFlex="70%">
                        <input class="col-input" [formControl]="nameControl" placeholder="Name" style="margin: 0;">
                    </div>
                </div>
                <div fxLayout="row" class="element-style">
                    <div class="label-style" fxFlex="30%">Full Name</div>
                    <div fxFlex="70%">
                        <input class="col-input" [formControl]="fullNameControl" placeholder="Full Name" style="margin: 0;">
                    </div>
                </div>
                <div fxLayout="row" class="element-style">
                    <div class="label-style" fxFlex="30%">Type</div>
                    <div fxFlex="70%">
                        <mat-select placeholder="Type" [formControl]="typeControl">
                            <mat-option [value]="'virtualcampus'">Virtual Campus</mat-option>
                            <mat-option [value]="'enovsky'">Enovsky</mat-option>
                            <mat-option [value]="'partner'">Partenaire</mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>
            <div fxFlex="50%" style="padding: 0 10px 0 10px;">
                <div fxLayout="row" class="element-style">
                    <div class="label-style" fxFlex="30%">Description</div>
                    <div fxFlex="70%">
                        <input class="col-input" [formControl]="descriptionControl" placeholder="Description" style="margin: 0;">
                    </div>
                </div>
                <div fxLayout="row" class="element-style">
                    <div class="label-style" fxFlex="30%">Active</div>
                    <div fxFlex="70%">
                        <mat-select placeholder="Active" [formControl]="activeControl">
                            <mat-option [value]="false">False</mat-option>
                            <mat-option [value]="true">True</mat-option>
                        </mat-select>
                    </div>
                </div>
                <div fxLayout="row" class="element-style">
                    <div class="label-style" fxFlex="30%">Image</div>
                    <div fxFlex="70%">
                        <input class="col-input file_erea" type="file" accept=".svg, .png, .jpg, .jpeg, .webp" (change)="onFileSelected($event)">
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="action == 'delete_advertisment'">You are about to delete this advertisment. Continue ?</div>
    </div>
    <hr>
    <div class="modal-footer" style="margin-top: 5px;">
        <button type="button" class="button-secondary-small left-side" (click)="close()">Close</button>
        <button type="button" class="button-validate-small" (click)="applyAction()" >Validate</button>
    </div>
</div>