<div fxLayout="row" style="padding: 20px 10px 20px 10px;">
    <div class="center-vertical" fxFlex="20%">
        <p>Total: {{this.showedRows.length}}</p>
    </div>
    <div class="center-vertical" fxFlex="40%" fxLayoutAlign="center center">
        <button type="button" class="button-secondary-small" (click)="settingAdvertisment('create_advertisment', {})"  style="white-space: nowrap; align-items: center; display: flex; margin-right: 3px;">
            <mat-icon fontIcon="settings" style="color: gray; cursor: pointer; margin-right: 5px; font-size: 20px; width: fit-content; height: fit-content;"></mat-icon>
            <div>Create New Advertisment</div>
        </button>
    </div>
    <div class="class-mat-field" fxFlex="25%"  fxLayoutAlign="center center">
        <button class="button-validate-small" (click) = "loadAdvertismentData()">Refresh</button>
    </div>

    <div class="center-vertical" fxFlex="40%" style="display: flex; align-items: center;">
        <div style="margin-left: auto;">
            <input class="col-input"  (keyup)="applyFilterLocal($event.target, 'all_column')" placeholder="Search">
        </div>
    </div>
</div>
<div class="table_div">
    <table>
        <thead>
        <tr>
            <th>
                <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'id', 'number')"></mat-icon>
                <div class="column-name">Id</div>
                <input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'id')">
            </th>
            <th>
                <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'name', 'string')"></mat-icon>
                <div class="column-name">Name</div>
                <input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'name')">
            </th>
            <th>
                <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'full_name', 'string')"></mat-icon>
                <div class="column-name">Full Name</div>
                <input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'full_name')">
            </th>
            <th>
                <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'description', 'string')"></mat-icon>
                <div class="column-name">Description</div>
                <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'description')"></div>
            </th>
            <th>
                <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'type', 'string')"></mat-icon>
                <div class="column-name">Type</div>
                <mat-select placeholder="Choose" style="height: 22px;" (selectionChange)="applyFilterLocal($event, 'type')">
                    <mat-option [value]="''">Choose</mat-option>
                    <mat-option [value]="'virtualcampus'">Vitual Campus</mat-option>
                    <mat-option [value]="'enovsky'">Enovsky</mat-option>
                    <mat-option [value]="'partner'">Partenaire</mat-option>
                </mat-select>
            </th>
            <th>
                <div class="column-name">Preview</div>
            </th>
            <th>
                <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'active', 'string')"></mat-icon>
                <div class="column-name">Active</div>
                <mat-select placeholder="Choose" style="height: 22px;" (selectionChange)="applyFilterLocal($event, 'active')">
                    <mat-option [value]="''">Choose</mat-option>
                    <mat-option [value]="false">False</mat-option>
                    <mat-option [value]="true">True</mat-option>
                </mat-select>
            </th>
            <th></th>
        </tr>
        </thead>
        <tbody #row_tbody *ngFor="let row of showedRows; let i = index">
            <tr (click)="toggleRow(row, $event.target, row_tbody)" [ngClass]="{'highlight-previous': row['source']=='content'}">
                <td>{{ row['id'] }}</td>
                <td>{{ row['name'] }}</td>
                <td>{{ row['full_name'] }}</td>
                <td>{{ row['description'] }}</td>
                <td>{{ row['type'] }}</td>
                <td style="display: flex; justify-content: center;"><img [src]="ndApiUrl+'/'+platformInfos['root']+'/'+row['file_name']" height="30"></td>
                <td [innerHTML]="stateTagsDisplay(row['active'])"></td>
                <td class="to_skip_element">
                    <div style="display: flex;">
                        <!-- <mat-icon  sorted="" fontIcon="open_in_new" style="color: gray; font-size: 20px;"></mat-icon> -->
                        <mat-icon sorted="" fontIcon="delete" style="color: gray; font-size: 20px;" (click)="settingAdvertisment('delete_advertisment', row)"></mat-icon>
                    </div>
                </td>
            </tr>
            <tr class="expand_detail" *ngIf="row['expanded']">
                <td colspan="8">
                    <app-detail-advertisment [rowInfos]="row" [userInfos]="userInfos" [platformInfos]="platformInfos" (inProgress)="setWaiting($event)" (updateAdvertisment)="setUpdateAdvertisment($event)"></app-detail-advertisment>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="showedRows.length === 0">
            <tr ><td colspan="8" style="text-align: center;">No matching records found</td></tr>
        </tbody>
    </table>
</div>
