import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import config from 'config/default.json';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-detail-promotion',
  templateUrl: './detail-promotion.component.html',
  styleUrls: ['./detail-promotion.component.scss']
})
export class DetailPromotionComponent {
  constructor(
    private elRef:ElementRef,
    private cdRef: ChangeDetectorRef,
    public helper: HelperModule,
    public dialog: MatDialog,
    private apiService: ApiServiceModule,
    private sharedService: SharedService,
    public sanitizer: DomSanitizer) {

  }
  @Input() rowInfos: { [x:string]: any } = new Map();
  @Input() userInfos: { [x:string]: any } = new Map();
  @Input() productData: any[] = [];
  @Input() platformInfos: { [x:string]: any } = new Map();
  @Output() updatePromotion = new EventEmitter<any>();
  @Output() inProgress = new EventEmitter<any>();

  nameControl = new FormControl();
  promoCodeControl = new FormControl();
  productControl = new FormControl();
  priceControl = new FormControl();
  priceRateControl = new FormControl();
  creditControl = new FormControl();
  creditRateControl = new FormControl();
  typeControl = new FormControl();
  durationControl = new FormControl();
  beginDateControl = new FormControl();
  expirationDateControl = new FormControl();
  activeControl = new FormControl();
  isDefaultPromo: boolean = false;

  ngOnInit() {
    this.nameControl.setValue(this.rowInfos['promo_name']);
    this.promoCodeControl.setValue(this.rowInfos['promo_code']);
    this.productControl.setValue(this.rowInfos['id_product']);
    this.priceControl.setValue(this.rowInfos['price']);
    this.priceRateControl.setValue(this.rowInfos['price_rate']);
    this.creditControl.setValue(this.rowInfos['credit']);
    this.creditRateControl.setValue(this.rowInfos['credit_rate']);
    this.typeControl.setValue(this.rowInfos['promo_type']);
    this.durationControl.setValue(this.rowInfos['duration']);
    this.beginDateControl.setValue(this.rowInfos['begin_date']);
    this.expirationDateControl.setValue(this.rowInfos['expiration_date']);
    this.activeControl.setValue(this.rowInfos['active']);
    this.isDefaultPromo = !['prime_sponsor','promotion'].includes(this.userInfos['promo_type']);
  }

  resetDate(){
    this.beginDateControl.setValue(null);
    this.expirationDateControl.setValue(null);
  }
  applyUpdate(){
    let promoName = this.nameControl.value;
    let promoCode = this.promoCodeControl.value;
    let idProduct = this.productControl.value;
    let price     = this.priceControl.value;
    let priceRate = this.priceRateControl.value;
    let credit    = this.creditControl.value;
    let creditRate = this.creditRateControl.value;
    let typePromo = this.typeControl.value;
    let duration  = this.durationControl.value;
    let active = this.activeControl.value;
    let beginDate: any = new Date(this.beginDateControl.value).toLocaleDateString("fr-FR").replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')+ ' 00:00:00';
    let expirationDate:any = new Date(this.expirationDateControl.value).toLocaleDateString("fr-FR").replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')+ ' 00:00:00';
    beginDate = beginDate.split('-')[0] == '1970' || beginDate.includes('Invalid')? null: beginDate;
    expirationDate = expirationDate.split('-')[0] == '1970' || expirationDate.includes('Invalid')? null: expirationDate;

    if(promoName && typePromo){
      
      let data = {
        name: promoName,
        promo_code: promoCode,
        id_product: this.isDefaultPromo? this.rowInfos['id_product']: idProduct,
        id_promotion: this.rowInfos['id'],
        price: price,
        price_rate: priceRate,
        credit:     credit,
        credit_rate: creditRate,
        promo_type: this.isDefaultPromo? this.rowInfos['promo_type']: typePromo,
        duration: duration,
        begin_date: beginDate,
        active: active,
        expiration_date: expirationDate,
        user_infos: this.userInfos,
        platform_infos: this.platformInfos,
      }
      this.sharedService.sendData({in_progress: true});
      this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/promotion/update', data).subscribe((response:any)=>{
        
        this.helper.showToast(response.message); 
        this.sharedService.sendData({in_progress: false});
      })
    }else this.helper.showToast('Not all fields are filled...')
    
  }
}
