<div class="modal-dialog modal-xl modal-content">
    <div class="modal-header">
        <div><h3 class="modal-title">{{contentTitle}}</h3></div>
    </div>
    <div class="modal-body">
        <div fxLayout="row" fxLayout.xs="column">
            <div class="label-style" fxFlex="10">Action</div>
            <div class="separator"></div>
            <div class="class-mat-field" fxFlex="30">
                <mat-select placeholder="Action" [formControl]="actionControl" (selectionChange)="switchAction()">
                    <mat-option [value]="'import'">Import</mat-option>
                    <mat-option [value]="'new'">Reset as New</mat-option>
                    <mat-option [value]="'archived'">Archive</mat-option>
                    <mat-option [value]="'replace'">Replace in place of</mat-option>
                </mat-select>
            </div>
            <div class="separator"></div>
            <div class="class-mat-field" fxFlex="60">
                <mat-select *ngIf="actionMode == 'select'" placeholder="Content" [formControl]="contentControl">
                    <input class="col-input" [formControl]="contentInputControl">
                    <mat-option [value]="0">Choose</mat-option>
                    <mat-option [value]="element['id_content']" *ngFor="let element of duplicatesRows; let i = index">Content {{element['id_content']}}</mat-option>
                </mat-select>
            </div>
        </div>
    </div>
    <hr>
    <div class="modal-footer" style="margin-top: 5px;">
        <button type="button" class="button-secondary-small left-side" (click)="close()">Close</button>
        <button type="button" class="button-validate-small" (click)="applyAction()">Validate</button>
    </div>
</div>