<div>
    <h2>Documentation</h2>
    <div style="margin: 5px; padding-bottom: 20px;">
        <button type="button" class="button-validate-small" (click)="playLeaning()">Play Learning</button>
    </div>
    
    <div *ngIf="userInfos['privilege'] >= 2">
        <div style="position: relative; display: flex; margin-left: auto; align-items: center; padding-right: 15px; width: fit-content;">
            <span class="switch-toggle" [ngClass]="{'switch-checked': toEdit}" (click)="toggleEditMode()">
              <small></small>
            </span>
            <label>Edit Documentation</label>
        </div>
    </div>
    <div style="padding: 15px; height: 80vh; ">
        <div id="editor-container"></div>
    </div>
</div>
