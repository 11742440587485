<div class="container">
    <div class="card" style="position: relative;">
        <div>
            <h2>Charge files</h2> 
            <p style="font-size: 14px;">NB: Only files extentions .svg, .png, .pdf, .jpg, .jpeg, .docx, .doc, .dot, .docm, .dotm, .dotx, .webp will be accepted and limited to 100M</p>
        </div>
        <div style="padding-top: 0px;">
            <div class="row" style="justify-content: center;">
                <div>
                    <div>
                        <div style="margin: 8px; display: flex; height: 20px;">
                            <div style="width: 90%; font-size : 16px; color: green;"><span class="text-success">{{successMessage}}</span> <span class="text-warning">{{warningMessage}}</span> <span class="text-danger">{{errorMessage}}</span></div>
                            <div style="width: 10%;">
                                <mat-icon class='bx bx-menu' style="color: var(--primary); float: right; padding-right: 30px; cursor: pointer;" aria-hidden="false" fontIcon="update" title="Reset" (click)="resetUpload()"></mat-icon>
                            </div>
                        </div>
                    </div>
                    <div class="drag_drop">
                        <input type="file" id="select_files" class="file_erea" accept=".svg, .png, .pdf, .jpg, .jpeg, .docx, .doc, .dot, .docm, .dotm, .dotx, .webp" (change)="onFileSelected($event)" (drop)="onFileDroped($event)" multiple="multiple" hidden>
                        <label for="select_files" id="select_label" style=" background-color: #f9f9f9; cursor: pointer; color: black; width: 100%; height:100%;">Select or Drag and drop files here</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>