import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges } from '@angular/core';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import config from 'config/default.json';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';

@Component({
  selector: 'app-vcl-content-project',
  templateUrl: './vcl-content-project.component.html',
  styleUrls: ['./vcl-content-project.component.scss']
})
export class VcContentProjectComponent{
  constructor(
    private elRef:ElementRef,
    public cdRef: ChangeDetectorRef,
    private apiService: ApiServiceModule,
    private sharedService: SharedService) {
  }
  @Input() userInfos: { [x:string]: any } = new Map();
  onlyMyProject:boolean = false;
  pageId = 'vcl-content-project'
  dataSource:{[x: string]: any}[] = [];
  platformInfos:{[x: string]: any} = {origin: 'virtualclass', key: 'vcl', root: 'VIRTUALCLASS'}
  

  ngOnInit(): void {
    if(Object.keys(this.userInfos).length) this.loadProjectData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userInfos'] && !changes['userInfos'].firstChange) {
      this.loadProjectData();
    }
  }

  loadProjectData(){
    this.sharedService.sendData({in_progress: true});
    this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/project/load', {id_user: this.userInfos['id_user'], platform_infos: this.platformInfos}).subscribe((response)=>{
      this.dataSource = response;
      this.sharedService.sendData({in_progress: false});
    })
  }
}
