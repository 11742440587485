import { ChangeDetectorRef, Component, ElementRef, Input, Renderer2, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
import config from 'config/default.json';
import { Observable } from 'rxjs';
import { MainComponent } from 'src/app/back-office/main/main.component';
@Component({
  selector: 'app-main-virtualcampus',
  templateUrl: './main-virtualcampus.component.html',
  styleUrls: ['./main-virtualcampus.component.scss']
})
export class MainVirtualCampusComponent {
  constructor(
    private elRef:ElementRef,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private sharedService: SharedService,
    private apiService: ApiServiceModule,
    ) {}
  pageId = 'vca-content-project';
  userInfos: { [x:string]: any } = new Map();

  ngOnInit(){
    this.sharedService.data.subscribe(data => {
      if(data['side_campus_page_id']){
        this.pageId = data['side_campus_page_id'];
        let param = {key: 'vca_last_page', value: this.pageId, id_user: this.userInfos['id_user']};
        this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/administration/save_settings', param).subscribe((response)=>{});
      }
      if(data['user_infos']){
        this.userInfos = data['user_infos'];
        this.pageId = this.userInfos?.['navigation_infos']?.vca_last_page || this.pageId;
      }
    });
    
  }
}
