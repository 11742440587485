import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private dataSubject = new BehaviorSubject<{ [x:string]: any }>({});
  data: Observable<{ [x:string]: any }> = this.dataSubject.asObservable();

  sendData(option: { [x:string]: any }) {
    this.dataSubject.next(option);
  }
}