<div class="header-top home-style">
    <div class="container">
        <ul>
            <li>
                <i class="fas fa-phone"></i>
                <a href="tel:+33 9 55 60 24 26">+33 9 55 60 24 26</a>
            </li>
            <li>
                <i class="fas fa-envelope"></i>
                <a href="mailto:contact@enovsky.com">contact@enovsky.com</a>
            </li>
        </ul>
    </div>
</div>
<mat-sidenav-container class="home-style" style="height: 100vh;">
    <mat-sidenav #sidenav class="mat-sidenav-style">
      <mat-nav-list>
        <div style="width: 100%; height: 100px; display: flex; justify-content: center;">
            <img src="assets/logos/logo_enovsky.png" style="height: 100px;">
        </div>
        <!-- <a [routerLink]="'policy'" [ngClass]="{'active-page' : currentRoute == 'policy'}" class="toolbar-menu" (click)="shareUserData()">Data Privacy</a>
        <a [routerLink]="'data-deletion'" [ngClass]="{'active-page' : currentRoute == 'data-deletion'}" class="toolbar-menu" (click)="shareUserData()">Secondary (VCL)</a>
         -->
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <div >
        <mat-toolbar class="mat-elevation-z2 toolbar-style">
            <mat-icon style="cursor: pointer;" aria-hidden="false" fontIcon="menu" title="Mop" (click)="sidenav.open()"></mat-icon>
            <div class="spacer"></div>
            <div class="spacer"></div>
            <a [routerLink]="'policy'" [ngClass]="{'active-page' : currentRoute == 'policy'}" class="toolbar-menu" (click)="shareUserData()">Politique</a>
            <!-- <a [routerLink]="'data-deletion'" [ngClass]="{'active-page' : currentRoute == 'data-deletion'}" class="toolbar-menu" (click)="shareUserData()">Secondary (VCL)</a> -->
            <a [routerLink]="''" class="dropdown profile">
                <img src="assets/logos/icon_enovsky.png">
            </a>
            
        </mat-toolbar>
        
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
</mat-sidenav-container>