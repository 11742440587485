import { ChangeDetectorRef, Component, ElementRef, Input, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { HelperModule } from 'src/app/back-office/modules/helper.module';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent {
  constructor(
    private elRef:ElementRef,
    private cdRef: ChangeDetectorRef,
    public sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private helper: HelperModule,
    public dialog: MatDialog
    ) {}

  PDFJS = (window as { [key: string]: any })["pdfjs-dist/build/pdf"];
  @Input() pdfData!: { [key: string]: any };
  @ViewChild('pdf_container') container!: ElementRef;
  isBuilt:boolean = false;
  
  defaultZoom = 1;
  zoomOut:boolean = false;
  zoomIn:boolean = false;
  ngAfterViewInit() {
    if (this.container?.nativeElement && this.pdfData && this.pdfData['url']) {
      this.container.nativeElement.style.height = this.pdfData['height']? `${this.pdfData['height']}px`: '100%';
      this.defaultZoom = this.pdfData['zoom']? this.pdfData['zoom']: this.defaultZoom;
      if(!this.pdfData['refresh']) this.showPages();

    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pdfData'] && !changes['pdfData'].firstChange) {
      this.container.nativeElement.style.height = this.pdfData['height']? `${this.pdfData['height']}px`: '100%';
      this.defaultZoom = this.pdfData['zoom']? this.pdfData['zoom']: this.defaultZoom;
      this.showPages();
    }
  }
  removeChildsFromElement(element:any){
    while (element.hasChildNodes()) {
      element.removeChild(element.lastChild);
    }
  }

  async showPages(){
    let that = this;
    this.PDFJS.GlobalWorkerOptions.workerSrc = 'assets/pdf/pdf.worker.js';
    this.removeChildsFromElement(this.container.nativeElement);
    let data = []
    try {
      const pdf = await this.PDFJS.getDocument(this.pdfData['url']).promise;
      const totalPages = pdf.numPages;
      
      for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
        const page = await pdf.getPage(pageNumber);
        let scale = this.defaultZoom;
        let viewport = page.getViewport({ scale });
  
        let canvasDivContainer = that.renderer.createElement('div');
        let canvas = that.renderer.createElement('canvas');
        let context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
  
        const renderContext = { canvasContext: context, viewport };
  
        await page.render(renderContext).promise;
        let zoomScale = this.defaultZoom;
        canvas.addEventListener('click', (event:any) => {
          if(this.zoomIn) zoomScale *= 1.2;
          if(this.zoomOut ) zoomScale /= 1.2;
          if(this.zoomIn || this.zoomOut) this.canvasZoom(event, canvas, viewport, page, zoomScale);
        });
        data.push(canvas.toDataURL('image/png', 1.0));
  
        canvasDivContainer.classList.add('canvasDivContainer');
        canvasDivContainer.appendChild(canvas);
        this.container.nativeElement.appendChild(canvasDivContainer);
      }
    }catch (error) {
      // Handle errors here
      console.error(error);
    }
  }
  selectZoomType(type:string){
    if(type == 'zoom_in'){
      this.zoomIn = !this.zoomIn;
      this.container.nativeElement.style.cursor = this.zoomIn? 'zoom-in':'pointer';
      if(this.zoomOut) this.zoomOut = false;
    }
    if(type == 'zoom_out'){
      this.zoomOut = !this.zoomOut;
      this.container.nativeElement.style.cursor = this.zoomOut? 'zoom-out':'pointer';
      if(this.zoomIn) this.zoomIn = false;
    }
    this.cdRef.detectChanges();
  }
  canvasZoom(event:any, canvas:any, viewport:any, page:any, zoomScale:number) {
    const x = event.clientX - canvas.getBoundingClientRect().left;
    const y = event.clientY - canvas.getBoundingClientRect().top;
    const zoomCenter = { x: x / zoomScale, y: y / zoomScale };
    const newViewport = viewport.clone({ scale: zoomScale, transform: [1, 0, 0, 1, -zoomCenter.x, -zoomCenter.y] });
  
    // Update canvas dimensions and re-render the page
    canvas.width = newViewport.width;
    canvas.height = newViewport.height;
    const context = canvas.getContext('2d');
    const renderContext = { canvasContext: context, viewport: newViewport };
    page.render(renderContext);
  }

}
