import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, combineLatest, takeUntil, map } from 'rxjs';
import defautlConfig from 'config/default.json';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})

export class ApiServiceModule { 
  
  constructor(private http: HttpClient) {
  }

  queryData(url:string, formData:object): Observable<any[]> {
    return this.http.post<any[]>(url, formData)
  }

  queryFile(url:string, formData:object): Observable<ArrayBuffer> {
    return this.http.post(url, formData, {responseType: "arraybuffer"});
  }
  queryFileAndData(url:string, formData:object) : any{
    return this.http.post(url, formData, { responseType: 'arraybuffer', observe: 'response' });
  }
  uploadDataAndFile(url:string, formData:object):  Observable<any[]> {
    return this.http.post<any[]>(url, formData)
  }
}