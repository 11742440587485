import { ChangeDetectorRef, Component , ElementRef, HostListener, Inject, Renderer2, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import config from 'config/default.json';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-profile-enovsky',
  templateUrl: './profile-enovsky.component.html',
  styleUrls: ['./profile-enovsky.component.scss']
})
export class ProfileEnovskyComponent {
  constructor(
    private apiService: ApiServiceModule,
    private helper: HelperModule,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<ProfileEnovskyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { [x:string]: any }
    ) {}
  urlApi1: string = "https://edu.enovsky.com";
  userControl = new FormControl();
  userInputControl = new FormControl();
  @ViewChild('users_ref', {static: true}) usersDiv!: ElementRef;
  @ViewChild('search_ref', {static: true}) searchInput!: ElementRef;
  page:string = this.data['page'];
  allUsers:any = [];
  searchInProgress:boolean = false;

  ngOnInit(){
    this.userInputControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((query) => {
        this.searchInProgress = true;
        return this.apiService.queryData(config.master.ndapi.url+'/enovsky/web/administration/search_user', {search_key: query})
      })
    ).subscribe((options) => {
      this.allUsers = options;
      this.searchInProgress = false;
    });
  }
  sendUserConnexion(){
    let setectedUser = this.allUsers.find((element:any) => element['id_user'] == this.userControl.value);
    if(setectedUser){
      this.dialogRef.close({confirm: true, connected_user: setectedUser});
    }else this.dialogRef.close(false);    
  }
  closeUserConnexion(){
    this.dialogRef.close(false);
  }

}
